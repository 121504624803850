import React from 'react';
import { Badge, Card, Button, Alert } from 'react-bootstrap';
import { BACKEND_URL } from '../../consts';

export default class AvailableCompetitionsComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      competitions: [],
    };
    this.removeCompetition = this.removeCompetition.bind(this);
    this.fetchCompetitions = this.fetchCompetitions.bind(this);
  }

  fetchCompetitions(){
    fetch(`${BACKEND_URL}/api/competition/get-competitions`,{
      "credentials": "include",
    })
      .then(data=>data.json())
      .then(data=>this.setState({competitions: [...data.competitions]}))
      .catch(error=>console.warn(error));
  }

  componentDidMount(){
    this.fetchCompetitions();
  }

  renderCompetition(c,k){
    return (
      <Card className="competition" key={k}>
        <Card.Body>
          <Card.Title>
            <Button variant="danger" onClick={()=>this.removeCompetition(c.id)}>Usuń</Button>
            {c.name}
          </Card.Title>
          <Card.Text>
            <Badge bg="primary">Początek: {c.start}</Badge>
            <Badge bg="secondary">Koniec: {c.end}</Badge>
            <div>Opis: {c.description}</div>
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  renderCompetitions(){
    return (
      <div className="competitions-list">
        {this.state.competitions.length ?
          this.state.competitions.map((c,k)=>this.renderCompetition(c,k))
          :
          (<Alert variant="info">Aktualnie brak zdefiniowanych rozgrywek</Alert>)}
      </div>
    )
  }

  removeCompetition(id){
    if(window.confirm('Potwierdzasz usunięcie?')){
      fetch(`${BACKEND_URL}/api/competition/${id}`,{
        "credentials": "include",
        method:'DELETE',
        headers: {
          'Content-type': 'appliation/json',
        }
      })
      .then(()=>this.fetchCompetitions())
      .catch(e=>console.warn(e));
    }
  }

  render(){
    return (
      <div className="competitions">
        <h1>Dostępne rozgrywki</h1>
        {this.renderCompetitions()}
      </div>
    )
  }
}
