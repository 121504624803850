import React from 'react';
import { Breadcrumb, Alert } from 'react-bootstrap';
import {BACKEND_OPEN_URL} from '../consts.js';
import { useParams } from 'react-router-dom';

function withParams(Component){
  return props => <Component {...props} params={useParams()}/>;
}

class DownloadTicketComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      isReady: false,
    };
    this.fetchItem = this.fetchItem.bind(this);
  }

  componentDidMount(){
    const {uid,verificationCode} = this.props.params;
    this.fetchItem(uid,verificationCode);
  }

  fetchItem(uid,verificationCode){
    fetch(`${BACKEND_OPEN_URL}/api/reservation/ticket/${uid}/download/${verificationCode}`,{
      credentials: "include",
      headers: {
        "Content-type": "application/json",
      }
    })
      .then(data=>data.blob())
      .then(data=>{
        var url = window.URL || window.webkitURL;
        var link = url.createObjectURL(data);
        var a = document.createElement("a");
        a.setAttribute("download", 'bilet.pdf');
        a.setAttribute("href", link);
        a.textContent="Bilet jest gotowy do pobrania - kliknij aby zapisać go na swoje urządzenie";
        let baseElement = document.body.querySelector("#linkparent");
        if(baseElement) baseElement.replaceWith(a);
        this.setState({
          isReady: true,
        })
      })
      .catch(err=>console.warn(err));
  }

  render(){
    return (
      <div className="home-component">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Start</Breadcrumb.Item>
        </Breadcrumb>
        {!this.state.isReady && (<Alert>Wskazany bilet zostanie za chwilę wygenerowany w tym oknie. Proszę go pobrać i zachować.</Alert>)}
        <div id="linkparent"></div>
      </div>
    );
  }
}

export default withParams(DownloadTicketComponent);
