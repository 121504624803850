import React from 'react';
import './NewEventComponent.scss';
import { Form, Button, Table } from 'react-bootstrap';
import { BACKEND_URL } from '../../consts';

const EMPTY_TICKET_TYPE = {
  name: '',
  values: [],
}

const DEFAULT_STATE = {
  name:'',
  description:'',
  startDate: '',
  startHour: '',
  endDate: '',
  endHour: '',
  sellingStartDate: '',
  sellingStartHour: '',
  fanClubSellingStartDate: '',
  fanClubSellingStartHour: '',
  sellingEndDate: '',
  sellingEndHour: '',
  competitionId: '',
  ticketTypes: [],
}

export default class NewEventComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      availableCompetitions: [],
      ...DEFAULT_STATE,
    }
    this.renderNewEventForm = this.renderNewEventForm.bind(this);
    this.getAvailableCompetitions = this.getAvailableCompetitions.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.createNewEvent = this.createNewEvent.bind(this);
    this.renderTickets = this.renderTickets.bind(this);
    this.updateTicketName = this.updateTicketName.bind(this);
    this.updateTicketValue = this.updateTicketValue.bind(this);
    this.addNewTicketType = this.addNewTicketType.bind(this);
  }

  componentDidMount(){
    this.getAvailableCompetitions();
  }

  getAvailableCompetitions(){
    fetch(`${BACKEND_URL}/api/competition/available`,{
      "credentials": "include",
    })
      .then(data=>data.json())
      .then(data=>{
        this.setState({availableCompetitions: [...data.competitions]});
        this.setState({competitionId: data.competitions[0].id});
      })
      .catch(error=>console.warn(error));
  }

  updateItem(name,value){
    this.setState({[name]:value});
  }

  createNewEvent(ev){
    ev.preventDefault();
    let newEvent = {
      'competitionId': this.state.competitionId,
      'name': this.state.name,
      'description': this.state.description,
      'startDate': this.state.startDate,
      'startHour': this.state.startHour,
      'endDate': this.state.endDate,
      'endHour': this.state.endHour,
      'sellingStartDate': this.state.sellingStartDate,
      'sellingStartHour': this.state.sellingStartHour,
      'fanClubSellingStartDate': this.state.fanClubSellingStartDate,
      'fanClubSellingStartHour': this.state.fanClubSellingStartHour,
      'sellingEndDate': this.state.sellingEndDate,
      'sellingEndHour': this.state.sellingEndHour,
      'ticketTypes': [...this.state.ticketTypes],
    };
    fetch(`${BACKEND_URL}/api/event/new`,{
      "credentials": "include",
      method: 'POST',
      body: JSON.stringify(newEvent),
      headers: {
        'Content-type': 'application/json',
      }
    })
      .then(data=>data.json())
      .then(data=>this.setState(DEFAULT_STATE))
      .catch(error=>console.warn(error));
  }

  updateTicketName(ev){
    let {name,value} = ev.target;
    let ticketTypes = this.state.ticketTypes.map((t,k)=>k==name ? {...t,name:value} : t);
    this.setState({
      ticketTypes,
    })
  }

  updateTicketValue(ticket,sid,value){
    let ticketTypes = this.state.ticketTypes.map((t,k)=>{
      return ticket === k ? {
        ...t,
        values: t.values.map(tv => tv.sector === sid ? {...tv, value} : tv),
      } : t;
    });
    this.setState({
      ticketTypes,
    })
  }

  addNewTicketType(ev,c){
    ev.preventDefault();
    let values = [];
    c.tribunes.forEach(t=>{
      t.sectors.forEach(s=>{
        values = [...values,{sector:s.id, value:0}];
      })
    })
    let newTicketType = {
      name:'',
      values,
    };
    this.setState({
      ticketTypes: [...this.state.ticketTypes, newTicketType],
    });
  }

  renderTickets(){
    const currentCompetition = this.state.availableCompetitions.find(c => c.id == this.state.competitionId);
    return (
      <Form.Group>
        <Table stripped bordered hover>
          <thead>
            <tr>
              <th rowSpan="2">Typ biletu</th>
              {currentCompetition?.tribunes.map((t,k) => (<th key={`t${k}`} colSpan={t.sectors?.length}>{t.name}</th>))}
            </tr>
            <tr>
              {currentCompetition?.tribunes.map((t,k) => t?.sectors.map((s,sk) => (<th key={`s${sk}`}>Sektor: {s.name}</th>)))}
            </tr>
          </thead>
          <tbody>
            {this.state.ticketTypes.map((t,ticketTypeNr) => (
              <tr key={`ticket${ticketTypeNr}`}>
                <td>
                  <Form.Control type="text" value={t.name} name={ticketTypeNr} onChange={this.updateTicketName} />
                </td>
                {currentCompetition?.tribunes.map((tr,competitionNr) => tr?.sectors.map((s,sk) => (
                  <td key={`s${sk}`}>
                    <Form.Control
                      type="number"
                      value={t.values.find(v=>v.sector.id === s.id)}
                      onChange={ev=>this.updateTicketValue(ticketTypeNr,s.id,ev.target.value)}
                    />
                  </td>
                )))}
              </tr>
            ))}
          </tbody>
        </Table>
        <div style={{textAlign:'center', margin:'2rem 0'}}>
          <Button variant="success" onClick={ev=>this.addNewTicketType(ev,currentCompetition)}>Dodaj nowy typ biletu</Button>
        </div>
      </Form.Group>
    )
  }

  renderNewEventForm(){
    return (
      <Form>
        <Form.Group>
          <Form.Label>Wydarzenie w ramach rozgrywek:</Form.Label>
          <Form.Select aria-label="Wybierz rozgrywki" name="competitionId" onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}>
            {this.state.availableCompetitions.map((c,k) => (<option key={k} value={c.id}>{c.name}</option>))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Nazwa wydarzenia</Form.Label>
          <Form.Control type="text" value={this.state.name} name="name" onChange={ev=>this.updateItem(ev.target.name,ev.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Opis wydarzenia</Form.Label>
          <Form.Control as="textarea" value={this.state.description} name="description" onChange={ev=>this.updateItem(ev.target.name,ev.target.value)} />
        </Form.Group>
        <div className="date-time-container">
          <Form.Group className="half">
            <Form.Label>Data rozpoczęcia</Form.Label>
            <Form.Control type="date" name="startDate" value={this.state.startDate}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
          </Form.Group>
          <Form.Group className="half">
            <Form.Label>Godzina rozpoczęcia</Form.Label>
            <Form.Control type="text" name="startHour" value={this.state.startHour}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
          </Form.Group>
          <Form.Group className="half">
            <Form.Label>Data zakończenia</Form.Label>
            <Form.Control type="date" name="endDate" value={this.state.endDate}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
          </Form.Group>
          <Form.Group className="half">
            <Form.Label>Godzina zakończenia</Form.Label>
            <Form.Control type="text" name="endHour" value={this.state.endHour}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
          </Form.Group>
          <Form.Group className="half">
            <Form.Label>Data rozpoczęcia sprzedaży biletów</Form.Label>
            <Form.Control type="date" name="sellingStartDate" value={this.state.sellingStartDate}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
          </Form.Group>
          <Form.Group className="half">
            <Form.Label>Godzina rozpoczęcia sprzedaży biletów</Form.Label>
            <Form.Control type="text" name="sellingStartHour" value={this.state.sellingStartHour}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
          </Form.Group>
          <Form.Group className="half">
            <Form.Label>Data rozpoczęcia sprzedaży biletów dla Fan Klubu</Form.Label>
            <Form.Control type="date" name="fanClubSellingStartDate" value={this.state.fanClubSellingStartDate}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
          </Form.Group>
          <Form.Group className="half">
            <Form.Label>Godzina rozpoczęcia sprzedaży biletów dla Fan Klubu</Form.Label>
            <Form.Control type="text" name="fanClubSellingStartHour" value={this.state.fanClubSellingStartHour}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
          </Form.Group>
          <Form.Group className="half">
            <Form.Label>Data zakończenia sprzedaży biletów</Form.Label>
            <Form.Control type="date" name="sellingEndDate" value={this.state.sellingEndDate}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
          </Form.Group>
          <Form.Group className="half">
            <Form.Label>Godzina zakończenia sprzedaży biletów</Form.Label>
            <Form.Control type="text" name="sellingEndHour" value={this.state.sellingEndHour}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
          </Form.Group>
        </div>
        <div className="tickets">
          <h3>Ustal rodzaje i ceny biletów:</h3>
          {this.renderTickets()}
        </div>
        <Button variant="primary" onClick={ev => this.createNewEvent(ev)}>Stwórz wydarzenie</Button>
      </Form>
    )
  }

  render(){
    return (
      <div className="new-event">
        <h1>Stwórz nowe wydarzenie</h1>
        {this.renderNewEventForm()}
      </div>
    );
  }
}
