import React from 'react';
import { Carousel, Card, ListGroup } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import './CurrentEventsComponent.scss';
import Slide1 from '../../assets/slide1.jpg';
import Slide2 from '../../assets/slide2.jpg';
import Slide3 from '../../assets/slide3.jpg';
import KartonPack from '../../assets/sponsors/kartonpack.png';
import NowaSol from '../../assets/sponsors/nowasol.jpg';
import Gedia from '../../assets/sponsors/gedia.png';
import Prezero from '../../assets/sponsors/prezero.jpg';
import PkmZachod from '../../assets/sponsors/pkmzachod.jpg';
import Nord from '../../assets/sponsors/nord.jpg';
import Enea from '../../assets/sponsors/enea.png';
import Electropoli from '../../assets/sponsors/electropoli.jpeg';
import Przelewy24Logo from '../../assets/p24logo.png';
import Astra from '../../assets/logo_astra.png';
import {BACKEND_OPEN_URL} from '../../consts';

export default class CurrentEventsComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      events: [],
    }
    this.fetchEvents = this.fetchEvents.bind(this);
    this.renderCurrentEvents = this.renderCurrentEvents.bind(this);
  }

  componentDidMount(){
    this.fetchEvents();
  }

  renderCurrentEvents(){
    const {events} = this.state;
    return (
      <div>
       {events.length ? (
         <ListGroup variant="flush">
          {events.map((e,k) => (
            <ListGroup.Item key={k}>
              <Link to={`/wydarzenie/${e.id}`}>{e.start} - {e.name} ({e.competition.name})</Link>
            </ListGroup.Item>
          ))}
         </ListGroup>
       ) : 'Aktualnie nie prowadzimy sprzedaży na żadne wydarzenia. Zapraszamy już wkrótce!'}
      </div>
    )
  }

  fetchEvents(){
    fetch(`${BACKEND_OPEN_URL}/api/event/get-events`,{
      credentials: 'include',
      method: 'GET',
    })
      .then(data=>data.json())
      .then(data=>this.setState({
        events: data.events,
      }))
      .catch(e=>console.warn(e));
  }

  render(){
    return (
      <div className="carousel-wrapper">
        <Carousel>
          <Carousel.Item>
            <img src={Slide1} alt="Astra Nowa Sól"/>
          </Carousel.Item>
          <Carousel.Item>
            <img src={Slide2} alt="Astra Nowa Sól"/>
          </Carousel.Item>
          <Carousel.Item>
            <img src={Slide3} alt="Astra Nowa Sól"/>
          </Carousel.Item>
        </Carousel>
        <Card className="events-info">
          <Card.Header>
            MKST Astra Nowa Sól
            <img src={Astra} alt="Logo Astry" />
          </Card.Header>
          <Card.Body>
            <Card.Title>Nadchodzące wydarzenia</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">Kup bilet online</Card.Subtitle>
            <Card.Text>
              {this.renderCurrentEvents()}
              <img src={Przelewy24Logo} alt="Przelewy24" />
            </Card.Text>
          </Card.Body>
        </Card>
        <div className="images">
          <img src={NowaSol} alt="Nowa Sól" />
          <img src={KartonPack} alt="Karton Pack s.a." />
          <img src={Nord} alt="Nord" />
          <img src={Gedia} alt="Gedia" />
          <img src={Prezero} alt="Prezero" />
          <img src={Enea} alt="Enea" />
          <img src={Electropoli} alt="Electropoli" />
        </div>
      </div>
    );
  }
}
