import React from 'react';
import { Breadcrumb, Card, ListGroup } from 'react-bootstrap';
import './HomeComponent.scss';
import {BACKEND_OPEN_URL} from '../../consts';

export default class HomeComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      tickets: [],
      carnets: [],
    }
    this.fetchItems = this.fetchItems.bind(this);
    this.renderTickets = this.renderTickets.bind(this);
    this.renderCarnets = this.renderCarnets.bind(this);
    this.downloadItem = this.downloadItem.bind(this);
  }

  componentDidMount(){
    if(!this.props.isLogged){
      window.location.replace("/");
    } else {
      this.fetchItems();
    }
  }

  fetchItems(){
    fetch(`${BACKEND_OPEN_URL}/user/api/tickets-carnets`,{
      credentials: 'include',
    })
      .then(data=>data.json())
      .then(data=>{
        if(data.tickets){
          this.setState({
            tickets: data.tickets,
          })
        }
        if(data.carnets){
          this.setState({
            carnets: data.carnets,
          })
        }
      })
  }

  downloadItem(type,id){
    fetch(`${BACKEND_OPEN_URL}/user/api/${type}/${id}/download`,{
      "credentials": "include",
      headers: {
        "Content-type": "application/json",
      }
    })
      .then(data=>data.blob())
      .then(data=>{
        let file = window.URL.createObjectURL(data);
        let newWindow = window.open();
        newWindow.location.assign(file);
      })
      .catch(err=>{
        window.location.replace("/");
      });
  }

  renderTickets(){
    const {tickets} = this.state;
    return (
      <div>
        <ListGroup variant="flush">
         {tickets.map((t,k) => (
           <ListGroup.Item key={k} className="event-link" onClick={()=>this.downloadItem('tickets',t.id)}>
             {t.eventName} - Sektor {t.sector}, {t.hasNumbers && `rząd ${t.row},`} miejsce {t.hasNumbers ? t.seatNumber : 'nienumerowane'} <small>bilet utworzony: {t.created}</small>
           </ListGroup.Item>
         ))}
        </ListGroup>
      </div>
    )
  }

  renderCarnets(){
    const {carnets} = this.state;
    return (
      <div>
        <ListGroup variant="flush">
         {carnets.map((c,k) => (
           <ListGroup.Item key={k} className="event-link" onClick={()=>this.downloadItem('carnets',c.id)}>
             {c.competitionName} - Sektor {c.sector}, {c.hasNumbers && `rząd ${c.row},`} miejsce {c.hasNumbers ? c.seatNumber : 'nienumerowane'} <small>karnet utworzony: {c.created}</small>
           </ListGroup.Item>
         ))}
        </ListGroup>
      </div>
    )
  }

  render(){
    const {tickets,carnets} = this.state;
    return (
      <div className="home-component">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Start</Breadcrumb.Item>
          <Breadcrumb.Item active>Moje konto</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header>Twoje bilety i karnety</Card.Header>
          <Card.Body>
            <Card.Title>Bilety</Card.Title>
            <Card.Text>
              {!tickets.length && "Nie posiadasz żadnych biletów w naszym systemie"}
              {!!tickets.length && this.renderTickets()}
            </Card.Text>
            <Card.Title>Karnety</Card.Title>
            <Card.Text>
            {!carnets.length && "Nie posiadasz żadnych karnetów w naszym systemie"}
            {!!carnets.length && this.renderCarnets()}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  }
}
