import React from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import {BACKEND_URL} from '../../consts';

export default class VerifyCarnetComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      uid: '',
      isInfoUpToDate: false,
      isCorrect: false,
      message: '',
    }
    this.updateItem = this.updateItem.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
  }

  handleCheck(ev){
    ev.preventDefault();
    fetch(`${BACKEND_URL}/api/carnet/verify/${this.state.uid}`,{
      "credentials": "include",
    })
      .then(data=>data.json())
      .then(data=>this.setState({
        isCorrect: data.isCorrect,
        message: data.message,
        isInfoUpToDate: true,
      }))
      .catch(e=>console.warn(e));
  }

  updateItem(ev){
    let {name,value} = ev.target;
    this.setState({
      isInfoUpToDate:false,
      [name]: value,
    })
  }

  renderForm(){
    return (
      <Form onSubmit={this.handleCheck}>
        <Form.Group>
          <Form.Control type="text" name="uid" onChange={this.updateItem} placeholder="ID karnetu"/>
          <Button onClick={this.handleCheck} variant="primary">Sprawdź</Button>
          {this.state.isInfoUpToDate && (<Alert variant={this.state.isCorrect?'info':'danger'}>{this.state.message}</Alert>)}
        </Form.Group>
      </Form>
    )
  }

  render(){
    return (<>
      <h1>Sprawdź karnet</h1>
      {this.renderForm()}
    </>);
  }
}
