import React from 'react';
import './NewTicketUserComponent.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Accordion, Button, Alert, Card, ListGroup, Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {BACKEND_OPEN_URL} from '../../consts';
import Toms100logo from "../../assets/toms100logo.svg";

function withParams(Component){
  return props => <Component {...props} params={useParams()}/>;
}

class NewTicketForEventComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      ticketsToAdd:[],
      email: '',
      secondEmail: '',
      event: {
        name: '',
        id: '',
        tribunes: [],
        ticketTypes: [],
      },
    }
    this.renderLabel = this.renderLabel.bind(this);
    this.addTicketToAdd = this.addTicketToAdd.bind(this);
    this.removeTicketToAdd = this.removeTicketToAdd.bind(this);
    this.toggleNewTicket = this.toggleNewTicket.bind(this);
    this.createNewTickets = this.createNewTickets.bind(this);
    this.updateEmail = this.updateEmail.bind(this);
    this.fetchEvent = this.fetchEvent.bind(this);
    this.getSectorIdOfCarnet = this.getSectorIdOfCarnet.bind(this);
  }

  componentDidMount(){
    this.fetchEvent();
    if(this.props.userName) this.setState({
      email: this.props.userName,
      secondEmail: this.props.userName,
    })
  }

  fetchEvent(){
    fetch(`${BACKEND_OPEN_URL}/api/event/${this.props.params.id}/with-carnets-and-tickets`,{
      "credentials": "include",
    })
      .then(data=>data.json())
      .then(data=>{
        if(data.success){
          this.setState({event:{...data.event}});
        } else {
          toast(data.error);
          window.location.replace("/");
        }})
      .catch(e=>{
        console.warn(e);
        window.location.replace("/");
      });
  }

  renderLabel(){
    let {event} = this.state;
    return (
      <div className="label">
        {!!event.name && (
          <Alert variant="primary">
            <h2>
              {!event.isPublic && (<img src={Toms100logo} class="toms100img" />)}
              {event.name} ({event.date})
            </h2>
          </Alert>
        )}
        {!!event.name && (
          <Alert variant="secondary">
            <h5>
              Początek sprzedaży: {event.sellingStartTime},
              koniec sprzedaży: {event.sellingEndTime}
            </h5>
          </Alert>
        )}
        <div className="tribunes">
          {event?.tribunes.map((t,k) => this.renderTribune(t,k))}
        </div>
      </div>
    )
  }

  renderTribune(t,k){
    return (
      <div className={`tribune${t.hasNumbers?' has-number':' no-numbers'}`} key={`tribune${k}`}>
        <h3>{t.name}</h3>
        <div className="sectors">
          {t.sectors.map((s,k) => this.renderSector(s,k,t.hasNumbers))}
        </div>
      </div>
    )
  }

  renderSector(s,k,hasNumbers){
    let howManySeats = 0;
    let howManyDisabledSeats = 0;
    let howManyReservedSeats = 0;
    let howManyTicketSeats = 0;
    let rowIds = [];
    let isNumberingPerRow = s.isNumberingPerRow;
    s.rows.forEach(r => {
      rowIds.push(r.id);
      howManySeats+=r.numberOfSeats;
      howManyDisabledSeats+=r.disabledSeats.length;
      howManyReservedSeats+=r.reservedSeats.length;
      howManyTicketSeats+=r.ticketSeats.length;
    });
    let howManyTicketsToAdd = 0;
    this.state.ticketsToAdd.forEach(t => {
      if(rowIds.includes(t.row)) howManyTicketsToAdd++;
    })
    let howManyEnabledSeats = howManySeats - howManyDisabledSeats;
    let howManyBusySeats = howManyTicketsToAdd + howManyReservedSeats + howManyTicketSeats;
    let howManyEmptySeats = howManyEnabledSeats - howManyBusySeats;
    let currentSeats = howManyEnabledSeats;
    return (
      <div className="sector" key={`sector${k}`} onClick={()=>(howManyEmptySeats>0)&&!hasNumbers&&this.addNewTicketForSector(s)}>
        <h4>Sektor: {s.name}</h4>
        {!hasNumbers && (
          <div className="available-places">
            <h5>
              Ilość dostępnych miejsc: {howManyEmptySeats}/{howManyEnabledSeats}
            </h5>
            <small>Aktualnie wybranych do dodania: {howManyTicketsToAdd}
            <br/>(Na tej trybunie nie ma numeracji miejsc)</small>
          </div>
        )}
        <div className="rows">
          {s.rows.map((r,k) => {
            currentSeats = !isNumberingPerRow ? (currentSeats - r.numberOfSeats + r.disabledSeats.length) : 0;
            return this.renderRow(r,k,hasNumbers,currentSeats);
          })}
        </div>
      </div>
    )
  }

  renderRow(r,k,hasNumbers,startNumber=0){
    return (
      <div className="row" key={`row${k}`}>
        <span className="row-label">{r.label}</span>
        {this.renderSeats(r,hasNumbers,startNumber)}
      </div>
    )
  }

  renderSeats(row,hasNumbers,startNumber){
    // tbd: remove "carnetSeats" - get info about current carnets from endpoint "current carnets"
    let seatNumber = 1+startNumber;
    return (
      Array(row.numberOfSeats).fill(0).map((s,k) => {
        let sn = seatNumber*1;
        let isDisabled = row.disabledSeats.includes(k+1);
        let isSelected = !isDisabled && this.state.ticketsToAdd.find(c => ((c.row === row.id) && (c.seatNr === sn)));
        let isReserved = row.reservedSeats.find(cs => cs.seat === sn);
        let isBusyWithTicket = row.ticketSeats.find(cs => cs.seat === sn);
        let isBusy = isReserved || isBusyWithTicket;
        let seatComment = row['ticketSeatsComments'][sn];
        return (
          <span
            className={`seat${!!seatComment?' has-comment':''}${isReserved?' busy-reserved':''}${isBusyWithTicket?' busy-ticket':''}${isDisabled?' disabled':''}${isSelected?' selected':''}`}
            onClick={()=>hasNumbers&&!isDisabled&&!isBusy&&this.toggleNewTicket(row,sn)}
            key={`r${row.label}s${k}`}
          >
            {!!seatComment && (<span className="comment-tooltip">{seatComment}</span>)}
            {(isDisabled||!hasNumbers)?'':seatNumber++}
          </span>
        )
      })
    )
  }

  removeTicketToAdd(row,seatNumber){
    this.setState({
      ticketsToAdd: this.state.ticketsToAdd.filter(c => !((c.row === row.id) && (c.seatNr === seatNumber))),
    })
  }

  removeTicketToAddByRowId(rowId,seatNumber){
    if(window.confirm('Czy na pewno usunąć?')){
      this.setState({
        ticketsToAdd: this.state.ticketsToAdd.filter(c => !((c.row === rowId) && (c.seatNr === seatNumber))),
      });
    }
  }

  addTicketToAdd(row,seatNumber){
    // tbd: provide default ticket type
    let message = this.state.ticketsToAdd.length ? 'Bilet dodany do listy która znajduje się u dołu strony' : 'Bilet dodany do listy u dołu strony. Wybierz typu biletu, podaj email. Po wybraniu "Zapłać" wybrane miejsca zostaną zarezerwowane na 15 minut. Brak płatności powoduje anulowanie rezerwacji.';
    toast(message);
    this.setState({
      ticketsToAdd: [...this.state.ticketsToAdd, {'row': row.id, 'seatNr': seatNumber, 'ticketType': ''}],
    })
  }

  toggleNewTicket(row,seatNumber){
    let ticketExists = this.state.ticketsToAdd.find(c => (c.row === row.id) && (c.seatNr === seatNumber));
    if(ticketExists) this.removeTicketToAdd(row,seatNumber);
    else this.addTicketToAdd(row,seatNumber);
  }

  addNewTicketForSector(sector){
    let numberOfSeatToReserve = null;
    let sectorToReserveSeat = sector.rows.find(row => {
      let seatNumber = 1;
      let seatToReserve = Array(row.numberOfSeats).fill(1).find((seat,key)=>{
        let sn = seatNumber*1;
        if(sn > row.numberOfSeats) return false;
        let isDisabled = row.disabledSeats.includes(key+1);
        if(!isDisabled) seatNumber++;
        let isSelected = !isDisabled && this.state.ticketsToAdd.find(c => ((c.row === row.id) && (c.seatNr === sn)));
        let isReserved = row.reservedSeats.find(cs => cs.seat === sn);
        let isBusyWithTicket = row.ticketSeats.find(cs => cs.seat === sn);
        let isBusy = isReserved || isBusyWithTicket;
        let isThisPlaceAllowedToReserve = !isDisabled && !isSelected && !isBusy;
        if(isThisPlaceAllowedToReserve){
          numberOfSeatToReserve = sn;
          return true;
        }
        return false;
      })
      return !!seatToReserve;
    })
    if(sectorToReserveSeat) this.addTicketToAdd(sectorToReserveSeat, numberOfSeatToReserve);
  }

  createNewTickets(ev){
    ev.preventDefault();
    // let transactionPanelUrl = 'https://sandbox.przelewy24.pl/trnRequest/';
    let transactionPanelUrl = 'https://secure.przelewy24.pl/trnRequest/';
    let tickets = this.state.ticketsToAdd.map(t => ({
      row: t.row,
      event: this.state.event.id,
      seatNr: t.seatNr,
      ticketType: t.ticketType,
      additionalInfo: t.additionalInfo,
    }));
    if(tickets && tickets.length){
      fetch(`${BACKEND_OPEN_URL}/api/reservation/new`,{
        credentials: "include",
        method: 'POST',
        body: JSON.stringify({'tickets':tickets,'email':this.state.email}),
        headers: {
          'Content-type': 'application/json',
        }
      })
      .then(data => data.json())
      .then(data => {
        if(data.success){
          this.setState({
            ticketsToAdd: [],
          });
          if(data.token){
            window.location.replace(`${transactionPanelUrl}${data.token}`);
          } else this.fetchEvent();
        } else {
          toast(data.message);
          if(data.shouldReload){
            this.setState({
              ticketsToAdd: [],
            });
            this.fetchEvent();
          }
        }
      })
      .catch(err=>console.warn(err));
    }
  }

  reservedSeatLabel(c){
    const event = this.state.event;
    let t = event?.tribunes.find(t => t.sectors.find(s => s.rows.find(r => r.id === c.row)));
    let s = t?.sectors.find(s => s.rows.find(r => r.id === c.row));
    let r = s?.rows.find(r => r.id === c.row);
    let label = `Trybuna: ${t.name}, sektor: ${s.name}`;
    label+= (t.hasNumbers) ? `, rząd ${r.label}, miejsce nr ${c.seatNr}` : ' (nienumerowane)';
    return label;
  }

  getSectorIdOfCarnet(c){
    let rowId = c.row;
    let tribune = this.state.event.tribunes.find(t=>t.sectors.find(s=>s.rows.find(r=>r.id === rowId)));
    let sector = tribune.sectors.find(s=>s.rows.find(r=>r.id === rowId));
    return sector.id;
  }

  updateTicketTicketType(t,ev){
    let newCarnet = {
      row: t.row,
      seatNr: t.seatNr,
      ticketType: ev.target.value,
    }
    let tickets = this.state.ticketsToAdd.map(tt => ((tt.row === t.row) && (tt.seatNr === t.seatNr)) ? newCarnet : tt);
    this.setState({
      ticketsToAdd: tickets,
    })
  }

  updateTicketAdditionalInformation(t,ev){
    let ticket = this.state.ticketsToAdd.find(tt => ((tt.row === t.row) && (tt.seatNr === t.seatNr)));
    if(ticket){
      ticket.additionalInfo = parseInt(ev.target.value);
      let tickets = this.state.ticketsToAdd.map(tt => ((tt.row === t.row) && (tt.seatNr === t.seatNr)) ? ticket : tt);
      this.setState({
        ticketsToAdd: tickets,
      })
    }
  }

  updateEmail(ev,type){
    if(!this.props.unserName){
      this.setState({
        [type]: ev.target.value,
      })
    }
  }

  isAllowedToSubmit(){
    const {email,secondEmail,event} = this.state;
    let everyTicketHasType = this.state.ticketsToAdd.length && this.state.ticketsToAdd.every(t => ("" != t.ticketType) && (event.isPublic || (parseInt(t.additionalInfo)>-1)));
    let emailCorrect = email && secondEmail && email === secondEmail;
    return everyTicketHasType && emailCorrect;
  }

  findValueOfTicketById(id){
    if(!id) return 0;
    const {event:{ticketTypes}} = this.state;
    let tickets = [];
    Object.keys(ticketTypes).forEach(tt => ticketTypes[tt].forEach(t => tickets.push(t)));
    let ticket = tickets?.find(t => t.id === parseInt(id));
    return ticket ? ticket.value : 0;
  }

  valueOfTickets(){
    const {ticketsToAdd,event:{ticketTypes}} = this.state;
    if(!ticketsToAdd.length) return 0;
    return ticketsToAdd.reduce((s,tta) => s+this.findValueOfTicketById(tta.ticketType),0);
  }

  numberOfTickets(){
    const {ticketsToAdd} = this.state;
    return ticketsToAdd.length;
  }

  renderTicketsToAdd(){
    let isAllowedToSubmit = this.isAllowedToSubmit();
    let valueOfTickets = this.valueOfTickets();
    let numberOfTickets = this.numberOfTickets();
    let {isPublic} = this.state.event;
    return (
      <Card>
        <Card.Body>
          <Card.Title>Wybrane bilety do wygenerowania:</Card.Title>
          <Card.Text>
            <ListGroup>
              {this.state.ticketsToAdd.map((c,k) => (
                <ListGroup.Item key={`cg${k}`}>
                  {this.reservedSeatLabel(c)}
                  <div className="select-ticket">
                    <b>Wybierz rodzaj biletu:</b>
                    <Form.Select
                      value={c.ticketType}
                      className="select-ticket-type"
                      onChange={(ev)=>this.updateTicketTicketType(c,ev)}
                    >
                      <option value="">Wybierz typ biletu</option>
                      {this.state.event.ticketTypes[this.getSectorIdOfCarnet(c)].map((tt,ttnr)=>(
                        <option key={`o${ttnr}`} value={tt.id}>{tt.name} ({tt.value} zł)</option>
                      ))}
                    </Form.Select>
                    {c.ticketType && !isPublic && (<Form.Control
                      required
                      className="select-ticket-type"
                      placeholder="Ilość osób towarzyszących"
                      type="number"
                      onChange={(ev)=>this.updateTicketAdditionalInformation(c,ev)}
                    />)}
                  </div>
                  <Button variant="danger" onClick={ev=>{ev.preventDefault();this.removeTicketToAddByRowId(c.row,c.seatNr)}}>
                    Usuń z listy
                  </Button>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Form.Group>
              <Form.Label>Podaj adres email na który zostaną przesłane wygenerowane bilety:</Form.Label>
              <Form.Control type="email" value={this.state.email} onChange={(ev)=>this.updateEmail(ev,'email')} disabled={!!this.props.userName} />
            </Form.Group>
            {!this.props.userName && (<Form.Group>
              <Form.Label>Powtórz adres email:</Form.Label>
              <Form.Control type="email" value={this.state.secondEmail} onChange={(ev)=>this.updateEmail(ev,'secondEmail')} disabled={!!this.props.userName} />
            </Form.Group>)}
            <Button onClick={this.createNewTickets} disabled={!isAllowedToSubmit} variant="success">
              Zapłać {valueOfTickets} zł za wybrane bilety: {numberOfTickets} (kliknięcie przenosi do serwisu Przelewy24)
            </Button>
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  renderCourt(){
    return (
      <div className="court">
        <span className="court-loc-label">Lokalizacja parkietu</span>
        <div className="court-field">
          <div className="_2-3-field"></div>
          <div className="_1-3-field first"></div>
          <div className="_1-3-field"></div>
          <div className="_2-3-field"></div>
        </div>
      </div>
    )
  }

  render(){
    return (
      <div className="user-tickets">
        {this.state.event && this.renderLabel()}
        {this.renderCourt()}
        {this.renderTicketsToAdd()}
        <ToastContainer/>
      </div>
    )
  }
}

export default withParams(NewTicketForEventComponent);
