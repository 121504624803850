import React from 'react';
import './LetInOutComponent.scss';
import {BACKEND_OPEN_URL} from '../consts.js';
import Logo from '../assets/logo_astra.png';
import {Button,Alert} from 'react-bootstrap';
import {QrScanner} from '@yudiel/react-qr-scanner';

export default class LetInOutComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      status:'default',
      mode:'in',
      message: '',
      isFetching: false,
    }
    this.toggleMode = this.toggleMode.bind(this);
    this.fetchItem = this.fetchItem.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  toggleMode(){
    let newMode = ('in'===this.state.mode) ? 'out':'in';
    this.setState({
      mode: newMode,
    })
  }

  fetchItem(uid){
    let {mode,isFetching} = this.state;
    if(!isFetching){
      this.setState({status:'checking',isFetching:true})
      fetch(`${BACKEND_OPEN_URL}/open/verify/${mode}/${uid}`,{
        "credentials": "include",
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(data => data.json())
      .then(data => {
        this.setState({
          status: data.isCorrect ? 'success' : 'error',
          message: data.message,
          isFetching: false,
        })
        setTimeout(() => {
          this.setState({status: 'default'})
        }, 1500);
      })
      .catch(error => console.warn(error));
    }
  }

  handleError(error){
    this.setState({status:'error'})
    setTimeout(() => {
      this.setState({status: 'default', message: '', isFetching: false})
    }, 1500)
    console.warn(error);
  }

  render(){
    const {status,mode,message} = this.state;
    return (
      <div className={`panel ${status}`}>
        {!!message && (<h5>{message}</h5>)}
        {'default'===status && (<QrScanner
          onDecode={this.fetchItem}
          onError={this.handleError}
        />)}
        {'default'!==status && (<Alert variant="info">{'success'===status ? 'OK' : 'Niewłaściwy bilet/karnet!'}</Alert>)}
        <Button onClick={this.toggleMode}>Zmień tryb na zgodę na {'in'===mode ? 'wyjście' : 'wejście'}</Button>
        <Alert variant="info">Aktualny tryb: sprawdzam zgodę na {'in'===mode ? 'wejście' : 'wyjście'}</Alert>
      </div>
    )
  }
}
