import React from 'react';
import { Form, Button } from 'react-bootstrap';
import TribuneComponent from './Tribune/TribuneComponent';
import { BACKEND_URL } from '../../consts.js';

const EMPTY_TRIBUNE = {
  name: '',
  sectors: [],
  has_numbers: false,
  first_number: 0,
}

export default class NewPlaceComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      newObject: {
        name: '',
        description: '',
        tribunes: [],
      }
    }
    this.renderOptions = this.renderOptions.bind(this);
    this.renderTribunes = this.renderTribunes.bind(this);
    this.addNewTribune = this.addNewTribune.bind(this);
    this.createPlace = this.createPlace.bind(this);
    this.removeTribune = this.removeTribune.bind(this);
  }

  updateNewObject(ev){
    this.setState({
      newObject: {
        ...this.state.newObject,
        [ev.target.name]: ev.target.value,
      },
    })
  }

  renderOptions(){
    return (
      <Form>
        <Form.Group>
          <Form.Label>Nazwa obiektu:</Form.Label>
          <Form.Control type="text" name="name" value={this.state.newObject.name} onChange={ev=>this.updateNewObject(ev)}/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Opis obiektu:</Form.Label>
          <Form.Control as="textarea" name="description" value={this.state.newObject.description} onChange={ev=>this.updateNewObject(ev)}/>
        </Form.Group>
      </Form>
    )
  }

  addNewTribune(){
    let tribunes = [...this.state.newObject.tribunes,{...EMPTY_TRIBUNE}];
    this.setState({
      newObject: {
        ...this.state.newObject,
        tribunes,
      }
    });
  }

  removeTribune(tribune_nr){
    this.setState({
      newObject: {
        ...this.state.newObject,
        tribunes: this.state.newObject.tribunes.filter((t,nr) => nr !== tribune_nr),
      }
    })
  }

  updateTribune(id,name,value){
    let newTribunes = this.state.newObject.tribunes.map((t,nr) => id===nr ? {...t, [name]:value} : t);
    this.setState({
      newObject: {
        ...this.state.newObject,
        tribunes: [...newTribunes],
      }
    })
  }

  renderTribunes(){
    return (
      <>
        <Button onClick={this.addNewTribune}>Dodaj nową trybunę</Button>
        {this.state.newObject.tribunes.map((tribune,id) => (
          <TribuneComponent
            key={`tribune-${id}`}
            tribune={tribune}
            onUpdate={(name,value)=>this.updateTribune(id,name,value)}
            removeTribune={()=>this.removeTribune(id)}
            editable={true}
          />
        ))}
      </>
    )
  }

  createPlace(){
    let newObject = JSON.stringify(this.state.newObject);
    fetch(`${BACKEND_URL}/api/sport-object/new`,{
      "credentials": "include",
      method: 'POST',
      body: newObject,
      headers: {
        'Content-type': 'application/json',
      }
    })
      .then(data => console.log(data))
      .catch(error => console.log(error))
  }

  render(){
    return (
      <>
        <div>
          <h1>Stwórz nowy obiekt</h1>
          {this.renderOptions()}
          {this.renderTribunes()}
        </div>
        <div>
          <Button onClick={this.createPlace}>Utwórz obiekt</Button>
        </div>
      </>
    );
  }
}
