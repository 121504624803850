import React from 'react';
import { BACKEND_URL } from '../../consts';
import TribuneComponent from './Tribune/TribuneComponent';
import { Card, Accordion, Button, Alert } from 'react-bootstrap';

export default class AvailablePlacesComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      objects: [],
    }
    this.getAvailablePlaces = this.getAvailablePlaces.bind(this);
    this.renderObjects = this.renderObjects.bind(this);
    this.removeObject = this.removeObject.bind(this);
  }

  componentDidMount(){
    this.getAvailablePlaces();
  }

  getAvailablePlaces(){
    fetch(`${BACKEND_URL}/api/sport-object`,{
      "credentials": "include",
    })
      .then(data => data.json())
      .then(data => this.setState({'objects':data.sport_objects}))
      .catch(error => console.warn(error));
  }

  removeObject(ev,name,id){
    ev.preventDefault();
    if(window.confirm("Czy na pewno chcesz usunąć obiekt "+name)){
      fetch(`${BACKEND_URL}/api/sport-object/${id}`,{
        "credentials": "include",
        method: 'DELETE',
      })
      .then(data => data.json())
      .then(data => this.getAvailablePlaces())
      .catch(error => console.warn(error));
    }
  }

  renderObject(obj,key){
    return (
      <Accordion.Item eventKey={key}>
        <Accordion.Header>
          <Button variant="danger" onClick={(ev)=>this.removeObject(ev,obj.name,obj.id)}>Usuń</Button>
          {obj.name}
        </Accordion.Header>
        <Accordion.Body>
          <Card className="single-object">
            <Card.Body>
              <Card.Title>{obj.description}</Card.Title>
              <Card.Text>
                <div className="tribunes">
                  {obj.tribunes.map((t,nr)=>(
                    <TribuneComponent
                      key={`tribune-${nr}`}
                      tribune={t}
                      onUpdate={()=>{}}
                      removeTribune={()=>{}}
                      editable={false}
                    />
                  ))}
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    )
  }

  renderObjects(){
    return this.state.objects.length ? (
      <div className="objects">
        <Accordion>
          {this.state.objects.map((obj,key) => this.renderObject(obj,key))}
        </Accordion>
      </div>
    ) : <Alert variant="primary">Aktualnie nie zdefiniowałeś żadnych obiektów</Alert>
  }

  render(){
    return(
      <>
        <h1>Dostępne obiekty</h1>
        {this.renderObjects()}
      </>
    )
  }
}
