import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';

function MenuComponent() {
  return (
    <Accordion defaultActiveKey={['0']}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Obiekty</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/new-place">Dodaj nowy obiekt</Link></li>
            <li><Link to="/available-places">Przeglądaj dostępne obiekty</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Rozgrywki</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/new-competition">Utwórz nowe rozgrywki</Link></li>
            <li><Link to="/competitions">Przeglądaj dostępne rozgrywki</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Wydarzenia</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/new-event">Dodaj nowe wydarzenie</Link></li>
            <li><Link to="/events">Przeglądaj dostępne wydarzenia z podziałem na rozgrywki</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Karnety</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/new-carnet">Utwórz nowy karnet administracyjny</Link></li>
            <li><Link to="/verify-carnet">Sprawdź karnet</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Bilety</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/">Utwórz nowy bilet administracyjny</Link></li>
            <li><Link to="/verify-ticket">Sprawdź bilet</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Opcje</Accordion.Header>
        <Accordion.Body>
          <ul>
            <li><Link to="/logout">Wyloguj się</Link></li>
            <li><Link to="/users">Zarządzanie użytkownikami</Link></li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default MenuComponent;
