import React from 'react';
import {Form,Button} from 'react-bootstrap';
import './Sector.scss';

const TOP_LEFT = 'TOP_LEFT';
const TOP_RIGHT = 'TOP_RIGHT';
const BOTTOM_LEFT = 'BOTTOM_LEFT';
const BOTTOM_RIGHT = 'BOTTOM_RIGHT';

const EMPTY_ROW = {
  label: '',
  numberOfSeats: 0,
  disabledSeats: [],
}

export default class SectorComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      newRowLabel: '',
      newRowNumberOfSeats: 0,
      disabledSeats: [],
    }
    this.updateSector = this.updateSector.bind(this);
    this.updateNewRowParams = this.updateNewRowParams.bind(this);
    this.addNewRow = this.addNewRow.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.renderSeats = this.renderSeats.bind(this);
    this.renderNewRowForm = this.renderNewRowForm.bind(this);
    this.renderNumberingTypeForm = this.renderNumberingTypeForm.bind(this);
  }

  updateSector(name,value){
    let newSector = {
      ...this.props.sector,
      [name]: value,
    }
    this.props.onUpdate(newSector);
  }

  updateNewRowParams(type,value){
    this.setState({[type]:value});
  }

  addNewRow(label,numberOfSeats){
    let newRow = {'label':this.state.newRowLabel,'numberOfSeats':this.state.newRowNumberOfSeats,'disabledSeats':[]};
    let rows = [...this.props.sector.rows,newRow];
    this.updateSector('rows',rows);
    this.setState({
      newRowLabel: '',
    })
  }

  addToDisabled(nr,snr){
    if(this.props.editable){
      let rows = this.props.sector.rows.map((r,rnr) => nr!==rnr ?
      {...r}
      :
      {
        ...r,
        disabledSeats: r.disabledSeats.find(ds=>ds===snr) ? [...r.disabledSeats.filter((ds=>ds!==snr))] : [...r.disabledSeats,snr],
      });
      this.updateSector('rows',rows);
    }
  }

  renderRow(row,nr){
    let arrayOfSeats = new Array(1*row.numberOfSeats).fill('a');
    let seatNumber = 1;
    return (
      <div className="row" key={`row${nr}`}>
        <div className="row-label">{row.label}</div>
        <div className="seats">
          {arrayOfSeats.map((s,snr) => {
            let isRowDisabled = row.disabledSeats?.find(ds=>ds===snr+1);
            return (
              <div key={snr}
                className={`seat${isRowDisabled?' disabled':''}${this.props.editable?'':' readonly'}`}
                onClick={()=>this.addToDisabled(nr,snr+1)}>
                  {isRowDisabled ? '' : seatNumber++}
              </div>
            )}
          )}
        </div>
      </div>
    )
  }

  renderSeats(){
    return this.props.sector.rows.map((row,nr) => this.renderRow(row,nr));
  }

  renderNewRowForm(){
    return (
      <Form.Group className="new-row-form">
        <Form.Label>Dodaj nowy rząd</Form.Label>
        <Form.Control
          placeholder="Opis"
          name="newRowLabel"
          autoComplete="off"
          value={this.state.newRowLabel}
          onChange={ev=>this.updateNewRowParams(ev.target.name,ev.target.value)}
          disabled={!this.props.editable}
        />
        <Form.Control
          placeholder="Ilość krzesełek"
          type="number"
          autoComplete="off"
          value={this.state.newRowNumberOfSeats}
          name="newRowNumberOfSeats"
          onChange={ev=>this.updateNewRowParams(ev.target.name,ev.target.value)}
          disabled={!this.props.editable}
        />
        {this.props.editable && (<Button type="primary" onClick={ev=>{ev.preventDefault();this.addNewRow()}}>Dodaj nowy rząd</Button>)}
      </Form.Group>
    )
  }

  renderNumberingTypeForm(){
    return (
      <Form.Group className="numbering-type-form">
        <Form.Check
          type="radio"
          label="Numeracja od: lewy górny"
          checked={this.props.sector.numbering_type == TOP_LEFT}
          onChange={ev=>this.updateSector('numbering_type',TOP_LEFT)}
        />
        <Form.Check
          type="radio"
          label="Numeracja od: lewy dolny"
          checked={this.props.sector.numbering_type == BOTTOM_LEFT}
          onChange={ev=>this.updateSector('numbering_type',BOTTOM_LEFT)}
        />
        <Form.Check
          type="radio"
          label="Numeracja od: prawy górny"
          checked={this.props.sector.numbering_type == TOP_RIGHT}
          onChange={ev=>this.updateSector('numbering_type',TOP_RIGHT)}
        />
        <Form.Check
          type="radio"
          label="Numeracja od: prawy dolny"
          checked={this.props.sector.numbering_type == BOTTOM_RIGHT}
          onChange={ev=>this.updateSector('numbering_type',BOTTOM_RIGHT)}
        />
      </Form.Group>
    )
  }

  render(){
    return (
      <div className="sector-wrapper">
        {this.props.editable && (<Button variant="danger" onClick={this.props.removeSector}>Usuń sektor</Button>)}
        {this.props.has_tribune_numbers && (<>
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Czy numeracja jest kontynuacją numeracji z trybuny?"
              autoComplete="off"
              checked={this.props.sector.is_numbering_continous}
              onChange={ev=>this.updateSector('is_numbering_continous',!!ev.target.checked)}
              disabled={!this.props.editable}
            />
            <Form.Check
              type="checkbox"
              label="Czy każdy rząd jest osobno numerowany?"
              autoComplete="off"
              checked={this.props.sector.is_numbering_per_row}
              onChange={ev=>this.updateSector('is_numbering_per_row',!!ev.target.checked)}
              disabled={!this.props.editable}
            />
          </Form.Group>
          {!this.props.sector.is_numbering_continous && !this.props.sector.is_numbering_per_row && (<Form.Group>
            <Form.Label>Początek numeracji:</Form.Label>
            <Form.Control
              name="start_number"
              autoComplete="off"
              value={this.props.sector.start_number}
              onChange={ev=>this.updateSector(ev.target.name,ev.target.value)}
              disabled={!this.props.editable}
            />
          </Form.Group>)}
          {!this.props.sector.is_numbering_per_row && this.renderNumberingTypeForm()}
        </>)}
        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Czy siedzenia występują bezpośrednio za sobą w poszczególnych rzędach?"
            checked={!this.props.sector.are_seats_crossed}
            onChange={ev=>this.updateSector('are_seats_crossed',!ev.target.checked)}
            disabled={!this.props.editable}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Nazwa sektora:</Form.Label>
          <Form.Control
            name="name"
            autoComplete="off"
            value={this.props.sector.name}
            onChange={ev=>this.updateSector(ev.target.name,ev.target.value)}
            disabled={!this.props.editable}
          />
        </Form.Group>
        {this.props.editable && this.renderNewRowForm()}
        {this.renderSeats()}
      </div>
    )
  }
}
