import React from 'react';
import './ResetComponent.scss';
import {BACKEND_OPEN_URL} from '../consts.js';
import Logo from '../assets/logo_astra.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

export default class ResetComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      email:'',
      sendingDone: false,
    }
    this.updateItem = this.updateItem.bind(this);
    this.submitReset = this.submitReset.bind(this);
  }

  updateItem(item,ev){
    this.setState({[item]:ev.target.value});
  }

  submitReset(ev){
    ev.preventDefault();
    let data = JSON.stringify({'email':this.state.email});
    this.setState({sendingDone:false});
    fetch(`${BACKEND_OPEN_URL}/api/reset-password-init`,{
      "credentials": "include",
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(data=>data.json())
      .then(data => !!data.success && this.setState({sendingDone:true,email:''}))
      .catch(error=>console.warn(error));
  }

  render(){
    return (
      <div className="login-panel">
        {this.state.sendingDone && (<Alert key="info" variant="info">Wysłaliśmy na Twojego maila informację z linkiem do resetu hasła.</Alert>)}
        <Form onSubmit={this.submitReset}>
          <img src={Logo} className="logo" alt="Logo Klubu"/>
          <Form.Group>
            <Form.Control placeholder="Email" type="text" name="email" onChange={(ev)=>this.updateItem('email',ev)} />
          </Form.Group>
          <Button variant="primary" type="submit">Zresetuj hasło</Button>
          <div>
            <ul>
             <li><Link to="/">Wróć do strony głównej</Link></li>
             <li><Link to="/register">Zarejestruj się</Link></li>
            </ul>
            <ul>
             <li><Link to="/login">Zaloguj się</Link></li>
            </ul>
          </div>
        </Form>
      </div>
    )
  }
}
