import React from 'react';
import './StartPanelComponent.scss';
import { Nav, Carousel, Dropdown, NavItem, NavLink } from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";
import Tauron1Liga from "../assets/tauron1liga.png";

export default class StartPanelComponent extends React.Component {
  constructor(props){
    super(props);
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  handleRedirect(key){
    document.location.replace(key);
  }

  render(){
    return (
      <div>
        <Nav variant="tabs" className="justify-content-end" activeKey="/" onSelect={this.handleRedirect}>
          <Nav.Item><Nav.Link eventKey="https://bilety.mkstastra.pl/regulamin.pdf">Regulamin serwisu</Nav.Link></Nav.Item>
          {this.props.isLogged && this.props.userName && (
            <Dropdown as={NavItem}>
              <Dropdown.Toggle as={NavLink}>{this.props.userName}</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item><Link to="/me">Moje konto</Link></Dropdown.Item>
                <Dropdown.Item><Link to="/logout">Wyloguj się</Link></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          {!this.props.isLogged && (<Nav.Item><Nav.Link eventKey="/login">Zaloguj się</Nav.Link></Nav.Item>)}
          {!this.props.isLogged && (<Nav.Item><Nav.Link eventKey="/register">Zarejestruj się</Nav.Link></Nav.Item>)}
          <Nav.Item><Nav.Link eventKey="https://mkstastra.pl">Oficjalna strona Klubu</Nav.Link></Nav.Item>
          {!true&&(<Nav.Item><Nav.Link eventKey="https://tauron1liga.pl"><img src={Tauron1Liga} alt="Tauron 1 Liga" /></Nav.Link></Nav.Item>)}
        </Nav>
        <section>
          <Outlet/>
        </section>
      </div>
    );
  }
}
