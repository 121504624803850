import React from 'react';
import { Alert, Accordion, Badge, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BACKEND_URL } from '../../consts';

export default class SelectEventToCreateTicketComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      competitions: [],
    }
  }

  fetchCompetitions(){
    fetch(`${BACKEND_URL}/api/competition/with-events`,{
      "credentials": "include",
    })
      .then(data=>data.json())
      .then(data=>this.setState({competitions: [...data.competitions]}))
      .catch(error=>console.warn(error));
  }

  componentDidMount(){
    this.fetchCompetitions();
  }

  renderCompetition(c,k){
    return (
      <Accordion.Item className="competition" key={k} eventKey={k}>
        <Accordion.Header>
          {c.name} (<Badge bg="primary">Początek: {c.start}</Badge>, <Badge bg="secondary">Koniec: {c.end}</Badge>)
        </Accordion.Header>
        <Accordion.Body>
          <ListGroup as="ol" numbered>
            {c.events.map((e,k) => this.renderEvent(e,k))}
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    )
  }

  renderEvent(e,k){
    return (
      <ListGroup.Item as="li" key={k}>
        <Link to={`/events/${e.id}/new-ticket`}>{e.name} ({e.start})</Link>
        <div>{e.description}</div>
      </ListGroup.Item>
    )
  }

  renderCompetitionsWithEvents(){
    return (
      <div className="competitions-list">
        {this.state.competitions.length ?
          this.state.competitions.map((c,k)=>this.renderCompetition(c,k))
          :
          (<Alert variant="info">Aktualnie brak zdefiniowanych rozgrywek</Alert>)}
      </div>
    )
  }

  render(){
    return (
      <>
        <h1>Wybierz wydarzenie aby utworzyć bilet:</h1>
        <Accordion>
          {this.renderCompetitionsWithEvents()}
        </Accordion>
      </>
    )
  }
}
