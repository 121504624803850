import React from 'react';
import { Accordion, Button, Alert, Card, ListGroup, Form } from 'react-bootstrap';
import {BACKEND_URL} from '../../consts';

export default class NewCarnetForCompetitionComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      isFetching: false,
      carnetsToAdd:[],
    }
    this.addCarnetToAdd = this.addCarnetToAdd.bind(this);
    this.removeCarnetToAdd = this.removeCarnetToAdd.bind(this);
    this.toggleNewCarnet = this.toggleNewCarnet.bind(this);
    this.createNewCarnets = this.createNewCarnets.bind(this);
    this.updateCarnetAdminNote = this.updateCarnetAdminNote.bind(this);
  }

  renderCompetition(){
    return (
      <Accordion.Item eventKey={this.props.eventKey}>
        <Accordion.Header>Karnety: {this.props.competition.name}</Accordion.Header>
        <Accordion.Body>
          <div className="tribunes">
            {this.props.competition.tribunes.map((t,k) => this.renderTribune(t,k))}
          </div>
          {this.renderCarnetsToAdd()}
          {this.renderPresentCarnets()}
        </Accordion.Body>
      </Accordion.Item>
    )
  }

  renderTribune(t,k){
    return (
      <div className={`tribune${t.hasNumbers?' has-number':' no-numbers'}`} key={`tribune${k}`}>
        <h3>Karnet: {t.name}</h3>
        <div className="sectors">
          {t.sectors.map((s,k) => this.renderSector(s,k,t.hasNumbers))}
        </div>
      </div>
    )
  }

  renderSector(s,k,hasNumbers){
    let howManySeats = 0;
    let howManyDisabledSeats = 0;
    let howManyCarnetSeats = 0;
    let rowIds = [];
    let isNumberingPerRow = s.isNumberingPerRow;
    s.rows.forEach(r => {
      rowIds.push(r.id);
      howManySeats+=r.numberOfSeats;
      howManyDisabledSeats+=r.disabledSeats.length;
      howManyCarnetSeats+=r.carnetSeats.length;
    });
    let howManyCarnetsToAdd = 0;
    this.state.carnetsToAdd.forEach(c => {
      if(rowIds.includes(c.row)) howManyCarnetsToAdd++;
    })
    let howManyEnabledSeats = howManySeats - howManyDisabledSeats;
    let howManyBusySeats = howManyCarnetsToAdd + howManyCarnetSeats;
    let howManyEmptySeats = howManyEnabledSeats - howManyBusySeats;
    let currentSeats = howManyEnabledSeats;
    return (
      <div className="sector" key={`sector${k}`} onClick={()=>!hasNumbers&&this.addNewCarnetForSector(s)}>
        <h4>Sektor: {s.name}</h4>
        {!hasNumbers && (
          <div className="available-places">
            <h5>
              Ilość dostępnych miejsc: {howManyEmptySeats}/{howManyEnabledSeats}
            </h5>
            <small>Aktualnie wybranych do dodania: {howManyCarnetsToAdd}
            <br/>(Na tej trybunie nie ma numeracji miejsc)</small>
          </div>
        )}
        <div className="rows">
          {s.rows.map((r,k) => {
            currentSeats = !isNumberingPerRow ? (currentSeats - r.numberOfSeats + r.disabledSeats.length) : 0;
            return this.renderRow(r,k,hasNumbers,currentSeats);
          })}
        </div>
      </div>
    )
  }

  renderRow(r,k,hasNumbers,startNumber=0){
    return (
      <div className="row" key={`row${k}`}>
        <span className="row-label">{r.label}</span>
        {this.renderSeats(r,hasNumbers,startNumber)}
      </div>
    )
  }

  renderSeats(row,hasNumbers,startNumber){
    // tbd: remove "carnetSeats" - get info about current carnets from endpoint "current carnets"
    let seatNumber = 1+startNumber;
    return (
      Array(row.numberOfSeats).fill(0).map((s,k) => {
        let sn = seatNumber*1;
        let isDisabled = row.disabledSeats.includes(k+1);
        let isSelected = !isDisabled && this.state.carnetsToAdd.find(c => ((c.row === row.id) && (c.seatNr === sn)));
        let isBusy = row.carnetSeats.find(cs => cs.seat === sn);
        return (
          <span
            className={`seat${isBusy?' busy':''}${isDisabled?' disabled':''}${isSelected?' selected':''}`}
            onClick={()=>hasNumbers&&!isDisabled&&!isBusy&&this.toggleNewCarnet(row,sn)}
            key={`r${row.label}s${k}`}
          >
            {(isDisabled||!hasNumbers)?'':seatNumber++}
          </span>
        )
      })
    )
  }

  removeCarnetToAdd(row,seatNumber){
    this.setState({
      carnetsToAdd: this.state.carnetsToAdd.filter(c => !((c.row === row.id) && (c.seatNr === seatNumber))),
    })
  }

  removeCarnetToAddByRowId(rowId,seatNumber){
    this.setState({
      carnetsToAdd: this.state.carnetsToAdd.filter(c => !((c.row === rowId) && (c.seatNr === seatNumber))),
    })
  }

  addCarnetToAdd(row,seatNumber){
    this.setState({
      carnetsToAdd: [...this.state.carnetsToAdd, {row: row.id, seatNr: seatNumber}],
    })
  }

  toggleNewCarnet(row,seatNumber){
    let carnetExists = this.state.carnetsToAdd.find(c => (c.row === row.id) && (c.seatNr === seatNumber));
    if(carnetExists) this.removeCarnetToAdd(row,seatNumber);
    else this.addCarnetToAdd(row,seatNumber);
  }

  addNewCarnetForSector(sector){
    let numberOfSeatToReserve = null;
    let sectorToReserveSeat = sector.rows.find(row => {
      let seatNumber = 1;
      let seatToReserve = Array(row.numberOfSeats).fill(1).find((seat,key)=>{
        let sn = seatNumber*1;
        if(sn > row.numberOfSeats) return false;
        let isDisabled = row.disabledSeats.includes(key+1);
        if(!isDisabled) seatNumber++;
        let isSelected = !isDisabled && this.state.carnetsToAdd.find(c => ((c.row === row.id) && (c.seatNr === sn)));
        let isBusy = row.carnetSeats.find(cs => cs.seat === sn);
        let isThisPlaceAllowedToReserve = !isDisabled && !isSelected && !isBusy;
        if(isThisPlaceAllowedToReserve){
          numberOfSeatToReserve = sn;
          return true;
        }
        return false;
      })
      return !!seatToReserve;
    })
    if(sectorToReserveSeat) this.addCarnetToAdd(sectorToReserveSeat, numberOfSeatToReserve);
  }

  createNewCarnets(ev){
    ev.preventDefault();
    let carnets = this.state.carnetsToAdd.map(c => ({
      row: c.row,
      competition: this.props.competition.id,
      seatNr: c.seatNr,
    }));
    fetch(`${BACKEND_URL}/api/carnet/new`,{
      "credentials": "include",
      method: 'POST',
      body: JSON.stringify({'carnets':carnets}),
      headers: {
        'Content-type': 'application/json',
      }
    })
      .then(data=>data.json())
      .then(data => {
        data.ids.forEach(id => {
          this.downloadCarnet(id);
        })
        this.props.refresh()
        this.setState({
          carnetsToAdd: [],
        })
      })
      .catch(err=>console.warn(err));
  }

  removeCarnet(id,doubleConfirm=false){
    if(window.confirm('Czy potwierdzasz usunięcie karnetu?')){
      if(doubleConfirm && window.confirm('Karnet zakupiony online - czy potwierdzasz?')){
        this.setState({isFetching:true});
        fetch(`${BACKEND_URL}/api/carnet/${id}`,{
          "credentials": "include",
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
          }
        })
        .then(data => this.props.refresh())
        .catch(error => console.warn(error))
        .finally(() => this.setState({isFetching:false}));
      } else {
        this.setState({isFetching:true});
        fetch(`${BACKEND_URL}/api/carnet/${id}`,{
          "credentials": "include",
          method: 'DELETE',
          headers: {
            'Content-type': 'application/json',
          }
        })
        .then(data => this.props.refresh())
        .catch(error => console.warn(error))
        .finally(() => this.setState({isFetching:false}));
      }
    }
  }

  updateCarnetAdminNote(id,note){
    this.setState({isFetching:true});
    fetch(`${BACKEND_URL}/api/carnet/${id}/note`,{
      "credentials": "include",
      method: 'PUT',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({'note':note}),
    })
    .then(data => this.props.refresh())
    .catch(error => console.warn(error))
    .finally(()=>this.setState({isFetching:false}));
  }

  downloadCarnet(id){
    fetch(`${BACKEND_URL}/api/carnet/${id}/download`,{
      "credentials": "include",
      headers: {
        "Content-type": "application/json",
      }
    })
      .then(data=>data.blob())
      .then(data=>{
        let file = window.URL.createObjectURL(data);
        let newWindow = window.open();
        newWindow.location.assign(file);
      })
      .catch(err=>console.warn(err));
  }

  renderPresentCarnets(){
    const competition = this.props.competition;
    const {isFetching} = this.state;
    return (
      <Card>
        <Card.Body>
          <Card.Title>Karnety wygenerowane na te rozgrywki:</Card.Title>
          <Card.Text>
            {competition.tribunes.map((tribune,key)=>(
              <div className="present-carnets-tribune" key={`tribune${key}`}>
                <Alert variant="primary">Karnety: {tribune.name}</Alert>
                <Accordion>
                  {tribune.sectors.map((sector,sectorkey) =>(
                    <Accordion.Item eventKey={sectorkey} key={key}>
                      <Accordion.Header>Sektor: {sector.name}</Accordion.Header>
                      <Accordion.Body>
                        <div className="present-carnets-sector" key={`t${key}sector${sectorkey}`}>
                          <ListGroup>
                            {sector.rows.map((row,rowkey) => row.carnetSeats.map((seat,seatkey) => (
                              <ListGroup.Item key={`t${key}s${sectorkey}r${rowkey}${seatkey}`}>
                                {this.reservedSeatLabel({'row':row.id,'seatNr':seat.seat})}<br/>
                                {seat.createTime && (<><small>Utworzony: {seat.createTime}</small><br/></>)}
                                {seat.generateTime && (<><small>Pobrany: {seat.generateTime}</small><br/></>)}
                                {seat.owner && 'brak' !== seat.owner && (<><strong>Właściciel: {seat.owner}</strong><br/></>)}
                                {seat.status && (<small>Status: {seat.status}</small>)}
                                <div className={`admin-comment ${('brak'===seat.owner) && !seat.adminNote ? 'expected' : ''}`}>
                                  Komentarz administratora:
                                  <Form.Control
                                    type="text"
                                    defaultValue={seat.adminNote}
                                    disabled={isFetching}
                                    onBlur={ev=>this.updateCarnetAdminNote(seat.carnetId,ev.target.value)}
                                  />
                                </div>
                                <Button
                                  variant="danger"
                                  onClick={()=>this.removeCarnet(seat.carnetId)}
                                  disabled={isFetching}
                                >
                                  Usuń {seat.owner && 'brak' !== seat.owner && (<>Uwaga - karnet zakupiony online!</>)}
                                </Button>
                                <Button
                                  variant="info"
                                  onClick={()=>this.downloadCarnet(seat.carnetId)}
                                  disabled={isFetching}
                                >
                                  Pobierz
                                </Button>
                              </ListGroup.Item>
                            )))}
                          </ListGroup>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            ))}
          </Card.Text>
        </Card.Body>
      </Card>
    )
  }

  reservedSeatLabel(c){
    const competition = this.props.competition;
    let t = competition.tribunes.find(t => t.sectors.find(s => s.rows.find(r => r.id === c.row)));
    let s = t.sectors.find(s => s.rows.find(r => r.id === c.row));
    let r = s.rows.find(r => r.id === c.row);
    let label = `Trybuna: ${t.name}, sektor: ${s.name}`;
    label+= (t.hasNumbers) ? `, rząd ${r.label}, miejsce nr ${c.seatNr}` : ' (nienumerowane)';
    return label;
  }

  renderCarnetsToAdd(){
    return (
      <Card>
      <Card.Body>
        <Card.Title>Wybrane karnety do wygenerowania:</Card.Title>
        <Card.Text>
          <ListGroup>
            {this.state.carnetsToAdd.map((c,k) => (
              <ListGroup.Item key={`cg${k}`}>
                {this.reservedSeatLabel(c)}
                <Button variant="danger" onClick={ev=>{ev.preventDefault();this.removeCarnetToAddByRowId(c.row,c.seatNr)}}>Usuń z listy</Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Button onClick={this.createNewCarnets}>Stwórz wybrane karnety</Button>
        </Card.Text>
      </Card.Body>
      </Card>
    )
  }

  render(){
    return this.renderCompetition();
  }
}
