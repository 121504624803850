import React from 'react';
import "./UsersComponent.scss";
import {BACKEND_URL} from '../../consts.js';
import {Table} from 'react-bootstrap';

export default class UsersComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      users: [],
    }
    this.fetchUsers = this.fetchUsers.bind(this);
    this.renderUsers = this.renderUsers.bind(this);
    this.changeUserAdmin = this.changeUserAdmin.bind(this);
    this.changeUserFanStatus = this.changeUserFanStatus.bind(this);
  }

  componentDidMount(){
    this.fetchUsers();
  }

  fetchUsers(){
    fetch(`${BACKEND_URL}/api/users`,{
      'credentials':'include',
    })
      .then(data=>data.json())
      .then(data=>this.setState({
        users:data.users,
      }))
      .catch(err=>console.warn(err));
  }

  changeUserAdmin(id){
    fetch(`${BACKEND_URL}/api/users/${id}/toggle-admin`,{
      'credentials':'include',
      method: 'PATCH',
    })
      .then(data=>data.json())
      .then(data=>this.fetchUsers())
      .catch(err=>console.warn(err));
  }

  changeUserFanStatus(id){
    fetch(`${BACKEND_URL}/api/users/${id}/toggle-fan`,{
      'credentials':'include',
      method: 'PATCH',
    })
      .then(data=>data.json())
      .then(data=>this.fetchUsers())
      .catch(err=>console.warn(err));
  }

  renderSingleUser(u,k){
    return (
      <tr key={k}>
        <td>{k+1}</td>
        <td>{u.name}</td>
        <td>
          {u.is_active ? 'Tak' : 'Nie'}
        </td>
        <td>
          <div className={`toggle-parent ${u.is_admin ? 'active' : ''}`} onClick={()=>this.changeUserAdmin(u.id)}>
            <div className="toggle-button"></div>
          </div>
        </td>
        <td>
          <div className={`toggle-parent ${u.is_fan ? 'active' : ''}`} onClick={()=>this.changeUserFanStatus(u.id)}>
            <div className="toggle-button"></div>
          </div>
        </td>
      </tr>
    )
  }

  renderUsers(){
    return (
      <Table striped bordered hover>
        <thead>
          <tr><th>LP</th><th>Email</th><th>Aktywny?</th><th>Czy admin?</th><th>Czy z Fan-Klubu?</th></tr>
        </thead>
        <tbody>
          {this.state.users.map((u,k) => this.renderSingleUser(u,k))}
        </tbody>
      </Table>
    )
  }

  render(){
    return (
      <div className="users-list">
        {this.renderUsers()}
      </div>
    )
  }
}
