import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { BACKEND_URL } from '../../consts';

const DEFAULT_STATE = {
  name:'',
  description:'',
  start: '',
  end: '',
  selectedObjectId: '',
}

export default class NewCompetitionComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      ...DEFAULT_STATE,
      availableSportObjects: [],
    }
    this.updateItem = this.updateItem.bind(this);
    this.getAvailableSportObjects = this.getAvailableSportObjects.bind(this);
    this.createNewCompetition = this.createNewCompetition.bind(this);
  }

  componentDidMount(){
    this.getAvailableSportObjects();
  }

  updateItem(name,value){
    this.setState({[name]:value});
  }

  getAvailableSportObjects(){
    fetch(`${BACKEND_URL}/api/sport-object/get-short`,{
      "credentials": "include",
    })
      .then(data=>data.json())
      .then(data=>{
        if(data.sport_objects.length){
          this.setState({
            availableSportObjects: [...data.sport_objects],
            selectedObjectId: data.sport_objects[0].id,
          });
      }})
      .catch(err=>console.warn(err));
  }

  createNewCompetition(ev){
    ev.preventDefault();
    let newCompetition = {
      'name': this.state.name,
      'description': this.state.description,
      'start': this.state.start,
      'end': this.state.end,
      'selectedObjectId': this.state.selectedObjectId,
    };
    fetch(`${BACKEND_URL}/api/competition/new`,{
      "credentials": "include",
      method: 'POST',
      body: JSON.stringify(newCompetition),
      headers: {
        'Content-type': 'application/json',
      }
    })
      .then(data=>data.json())
      .then(data=>this.setState(DEFAULT_STATE))
      .catch(error=>console.warn(error));
  }

  renderNewCompetitionForm(){
    return (
      <Form>
        <Form.Group>
          <Form.Label>Obiekt na którym będą się odbywały rozgrywki</Form.Label>
          <Form.Select name="selectedObjectId" onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}>
            {this.state.availableSportObjects.map((o,k)=>(<option key={k} value={o.id}>{o.name}</option>))}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Label>Nazwa rozgrywek</Form.Label>
          <Form.Control type="text" value={this.state.name} name="name" onChange={ev=>this.updateItem(ev.target.name,ev.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Opis rozgrywek</Form.Label>
          <Form.Control as="textarea" value={this.state.description} name="description" onChange={ev=>this.updateItem(ev.target.name,ev.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Data początku</Form.Label>
          <Form.Control type="date" name="start" value={this.state.start}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
        </Form.Group>
        <Form.Group>
          <Form.Label>Data końca</Form.Label>
          <Form.Control type="date" name="end" value={this.state.end}  onChange={ev=>this.updateItem(ev.target.name,ev.target.value)}/>
        </Form.Group>
        <Button variant="primary" onClick={ev => this.createNewCompetition(ev)}>Stwórz rozgrywki</Button>
      </Form>
    )
  }

  render(){
    return (
      <div className="new-competition">
        <h1>Stwórz nowe rozgrywki</h1>
        {this.renderNewCompetitionForm()}
      </div>
    )
  }
}
