import React from 'react';
import './LoginComponent.scss';
import {BACKEND_OPEN_URL} from '../consts.js';
import Logo from '../assets/logo_astra.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';

export default class LoginComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      login:'',
      password:'',
      loginFailed: false,
    }
    this.updateItem = this.updateItem.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
  }

  updateItem(item,ev){
    this.setState({[item]:ev.target.value});
  }

  submitLogin(ev){
    ev.preventDefault();
    let data = JSON.stringify({'username':this.state.login,'password':this.state.password});
    this.setState({loginFailed:false});
    fetch(`${BACKEND_OPEN_URL}/api/login`,{
      "credentials": "include",
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(data=>data.json())
      .then(data => data.success ? window.location.replace("/") : this.setState({loginFailed:true}))
      .catch(error=>console.warn(error));
  }

  render(){
    return (
      <div className="login-panel">
        {this.state.loginFailed && (<Alert key="warning" variant="warning">Podane dane są nieprawidłowe</Alert>)}
        <Form onSubmit={this.submitLogin}>
          <img src={Logo} className="logo" alt="Logo Klubu"/>
          <Form.Group>
            <Form.Control placeholder="Login" type="text" name="login" onChange={(ev)=>this.updateItem('login',ev)} />
          </Form.Group>
          <Form.Group>
            <Form.Control placeholder="Hasło" type="password" name="password" onChange={(ev)=>this.updateItem('password',ev)} />
          </Form.Group>
          <Button variant="primary" type="submit">Zaloguj się</Button>
          <div>
            <ul>
             <li><Link to="/">Wróć do strony głównej</Link></li>
             <li><Link to="/register">Zarejestruj się</Link></li>
            </ul>
            <ul>
             <li><Link to="/reset-password">Przypomnij hasło</Link></li>
            </ul>
          </div>
        </Form>
      </div>
    )
  }
}
