import React from 'react';
import {BACKEND_OPEN_URL} from '../consts.js';
import Logo from '../assets/logo_astra.png';
import {Alert,Toast} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

function withParams(Component){
  return props => <Component {...props} params={useParams()}/>;
}

class ConfirmAccount extends React.Component {

  constructor(props){
    super(props);
    this.state={
      isFetching: false,
      hasResult: false,
      hasError: false,
      errorMessage: '',
    }
    this.fetchItem = this.fetchItem.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

  componentDidMount(){
    const {id} = this.props.params;
    if(id){
      this.fetchItem(id);
    } else window.location.replace("/");
  }

  fetchItem(id){
    fetch(`${BACKEND_OPEN_URL}/api/confirm/${id}`,{
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(data => data.json())
      .then(data => {
        if(!data.success){
          this.setState({
            hasError: true,
            errorMessage: data.message,
          })
        }
      })
      .finally(() => {
        this.setState({
          isFetching: false,
          hasResult: true,
        })
      })
      .catch(err=>console.warn(err));
  }

  renderContent(){
    const {isFetching,hasResult,hasError,errorMessage} = this.state;
    return (
      <div>
        <img src={Logo} className="logo" alt="Logo Klubu"/>
        {!isFetching && !hasResult && (<Alert variant="primary">Poczekaj na potwierdzenie konta</Alert>)}
        {!isFetching && hasResult && hasError && (<Alert variant="error">{errorMessage}</Alert>)}
        {!isFetching && hasResult && !hasError && (
          <Alert variant="success">Twoje konto zostało potwierdzoene, możesz się <Link to="/login">zalogować</Link></Alert>
        )}
      </div>
    )
  }

  render(){
    const {isLogged} = this.props;
    return (
      <div className="confirmation">
        {isLogged && (
          <Toast>
            <Toast.Header>Logowanie</Toast.Header>
            <Toast.Body>Wyloguj się aby potwierdzić inne konto</Toast.Body>
          </Toast>
          )
        }
        {!isLogged && this.renderContent()}
      </div>
    )
  }
}

export default withParams(ConfirmAccount);
