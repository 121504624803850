import React from 'react';
import { BACKEND_URL } from '../../consts';
import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

function withParams(Component){
  return props => <Component {...props} params={useParams()}/>;
}

class TransactionsComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      transactions: {},
      number: 0,
      value: 0,
    }
    this.fetchItems = this.fetchItems.bind(this);
    this.renderTransactions = this.renderTransactions.bind(this);
  }

  componentDidMount(){
    this.fetchItems(this.props.params.id);
  }

  fetchItems(id){
    fetch(`${BACKEND_URL}/api/event/${id}/transactions`,{
      "credentials": "include",
    })
      .then(data=>data.json())
      .then(data=>this.setState({
        transactions:data.transactions,
        number: data.number,
        value: data.value,
      }))
      .catch(e=>console.warn(e));
  }

  renderTransactions(){
    const {transactions} = this.state;
    let sumT=0, sumAI=0, sumInc=0;
    return (
      <Table>
        <thead>
          <th>LP</th>
          <th>Id</th>
          <th>Utworzony</th>
          <th>Przez</th>
          <th>Ile biletów</th>
          <th>Id w systemie Przelewy24</th>
          <th>Metoda płatności</th>
          <th>Tytuł płatności</th>
          <th>Wartość transakcji</th>
          <th>Opis transakcji</th>
          <th>Dodatkowa informacja</th>
        </thead>
        <tbody>
        {Object.keys(transactions).map((t,k) => {
          if(transactions[t].additionalInfo) sumAI+=1*transactions[t].additionalInfo;
          if(transactions[t].howManyReservations) sumT+=1*transactions[t].howManyReservations;
          if(transactions[t].p24amount) sumInc+=1*transactions[t].p24amount;
          return (
            <tr key={k}>
              <td>{k+1}</td>
              <td>{t}</td>
              <td>{transactions[t].created}</td>
              <td>{transactions[t].createdBy}</td>
              <td>{transactions[t].howManyReservations}</td>
              <td>{transactions[t].p24orderId}</td>
              <td>{transactions[t].p24methodId}</td>
              <td>{transactions[t].p24statement}</td>
              <td>{transactions[t].p24amount}</td>
              <td>{transactions[t].p24description}</td>
              <td>{transactions[t].additionalInfo || "bd"}</td>
            </tr>
          )})}
          <tr><td colSpan="4"></td><td>{sumT}</td><td colSpan="3"></td><td>{sumInc} zł</td><td></td><td>{sumAI}</td></tr>
        </tbody>
      </Table>
    )
  }

  render(){
    let {number,value} = this.state;
    return (
      <div className="transactions">
        <h1>Transakcje płatnicze wygenerowane dla tego wydarzenia:</h1>
        {this.renderTransactions()}
        <h2>Ilość sprzedanych biletów: {number}</h2>
        <h2>Wartość sprzedanych biletów: {value} zł</h2>
        <h5>Średnia wartość biletu: {Math.round(value/number,5)} zł</h5>
      </div>
    );
  }
}

export default withParams(TransactionsComponent);
