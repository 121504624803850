import React from 'react';
import './RegisterComponent.scss';
import {BACKEND_OPEN_URL} from '../consts.js';
import Logo from '../assets/logo_astra.png';
import {Button,Form,Alert} from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default class RegisterComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      login:'',
      password:'',
      secondPassword:'',
      errorMessages:{},
      registerFailed: false,
      isRegistered: false,
      isFetching: false,
    }
    this.updateItem = this.updateItem.bind(this);
    this.submitRegister = this.submitRegister.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
  }

  componentDidMount(){
    if(this.props.isLogged){
      window.location.replace('/');
    }
  }

  updateItem(item,ev){
    this.setState({
      [item]:ev.target.value,
      errorMessages: {},
      registerFailed: false,
    });
  }

  isEmailValid(email){
    return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  }

  isFormValid(){
    const {login,password,secondPassword} = this.state;
    let errorMessages = {};
    if(''==login){
      errorMessages['login']='Podaj prawidłowy login';
    }
    if(!this.isEmailValid(login)){
      errorMessages['login']='Podaj prawidłowy login';
    }
    if(''==password){
      errorMessages['password']='Zdefiniuj swoje hasło';
    }
    if(password!==secondPassword){
      errorMessages['secondPassword']='Podane hasła nie są takie same';
    }
    if(password.length<8){
      errorMessages['password']='Hasło powinno zawierać min. 8 znaków';
    }
    let hasUppercase =password.match(/[A-Z]/);
    let hasLowercase = password.match(/[a-z]/);
    let hasNumber    = password.match(/[0-9]/);
    if(!hasUppercase || !hasLowercase || !hasNumber){
      errorMessages['password'] = 'Hasło powinno zawierać małe i duże litery, znaki specjalne oraz cyfry';
    }
    this.setState({
      registerFailed: !!Object.keys(errorMessages).length,
      errorMessages,
    });
    return !Object.keys(errorMessages).length;
  }

  submitRegister(ev){
    ev.preventDefault();
    if(!this.isFormValid()) return;
    let data = JSON.stringify({
      'username':this.state.login,
      'password':this.state.password,
      'secondPassword':this.state.secondPassword,
    });
    this.setState({loginFailed:false,isFetching:true});
    fetch(`${BACKEND_OPEN_URL}/api/register`,{
      "credentials": "include",
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(data=>data.json())
      .then(data => this.setState({
        registerFailed:!data.success,
        errorMessages:data.errorMessages,
        isRegistered:data.success,
        login:'',
        password:'',
        secondPassword:'',
      }))
      .finally(()=>{
        this.setState({
          isFetching: false,
        })
      })
      .catch(error=>console.warn(error));
  }

  render(){
    const {registerFailed,errorMessages,isRegistered,login,password,secondPassword,isFetching} = this.state;
    return (
      <div className="register-panel">
        {isRegistered && (<Alert key="warning" variant="warning">Konto zostało założone. Aby je aktywować sprawdź maila.</Alert>)}
        {registerFailed && (<Alert key="warning" variant="warning">W formularzu występują błędy</Alert>)}
        <Form onSubmit={this.submitRegister}>
          <img src={Logo} className="logo" alt="Logo Klubu"/>
          <Form.Group>
            <Form.Control
              placeholder="Email/login"
              type="text"
              name="login"
              value={login}
              autoComplete="off"
              disabled={isFetching}
              onChange={(ev)=>this.updateItem('login',ev)}
            />
            {registerFailed && errorMessages && errorMessages['login'] && (
              <Alert key="warning" variant="warning">{errorMessages['login']}</Alert>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Control
              placeholder="Hasło"
              type="password"
              name="password"
              disabled={isFetching}
              value={password}
              onChange={(ev)=>this.updateItem('password',ev)}
            />
            {registerFailed && errorMessages && errorMessages['password'] && (
              <Alert key="warning" variant="warning">{errorMessages['password']}</Alert>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Control
              placeholder="Powtórz hasło"
              type="password"
              name="secondPassword"
              disabled={isFetching}
              value={secondPassword}
              onChange={(ev)=>this.updateItem('secondPassword',ev)}
            />
            {registerFailed && errorMessages && errorMessages['secondPassword'] && (
              <Alert key="warning" variant="warning">{errorMessages['secondPassword']}</Alert>
            )}
          </Form.Group>
          <Button variant="primary" type="submit">Zarejestruj się</Button>
          <div>
            <ul>
             <li><Link to="/">Wróć do strony głównej</Link></li>
             <li><Link to="/login">Zaloguj się</Link></li>
            </ul>
          </div>
        </Form>
      </div>
    )
  }
}
