import React from 'react';
import { BACKEND_URL } from '../../consts';
import { Outlet, Link } from "react-router-dom";
import { Accordion, Button, Alert, ListGroup } from 'react-bootstrap';

export default class AvailableEventsComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      competitions: [],
    }
    this.getCompetitionsWithEvents = this.getCompetitionsWithEvents.bind(this);
  }

  componentDidMount(){
    this.getCompetitionsWithEvents();
  }

  getCompetitionsWithEvents(){
    fetch(`${BACKEND_URL}/api/competition/with-events`,{
      "credentials": "include",
    })
      .then(data=>data.json())
      .then(data=>this.setState({competitions:[...data.competitions]}))
      .catch(e=>console.warn(e));
  }

  removeEvent(ev,id){
    ev.preventDefault();
    if(window.confirm('Czy potwierdzasz usunięcie?')){
      fetch(`${BACKEND_URL}/api/event/${id}`,{
        "credentials": "include",
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json',
        }
      })
      .then(data=>this.getCompetitionsWithEvents())
      .catch(e=>console.warn(e));
    }
  }

  renderEvent(e,k){
    return (
      <ListGroup.Item key={k} className="single-event">
        <Button onClick={ev=>this.removeEvent(ev,e.id)} variant="danger">Usuń</Button>
        <Link to={`/events/${e.id}/transactions`}>{e.name} ({e.start})</Link>
      </ListGroup.Item>
    )
  }

  renderCompetition(c,k){
    return (
      <Accordion.Item key={k} eventKey={k}>
        <Accordion.Header>{c.name}</Accordion.Header>
        <Accordion.Body>
          {c.events.length ? (
            <ListGroup>
              {c.events.map((e,k) => this.renderEvent(e,k))}
            </ListGroup>
          ) : (<Alert variant="primary">Brak wydarzeń w ramach tych rozgrywek</Alert>)
          }
        </Accordion.Body>
      </Accordion.Item>
    )
  }

  renderCompetitionsWithEvents(){
    return (
      <Accordion>
        {this.state.competitions.length ?
          this.state.competitions.map((c,k) => this.renderCompetition(c,k))
          :
          (<Alert variant="primary">Brak rozgrywek</Alert>)
        }
      </Accordion>
    )
  }

  render(){
    return (
      <div className="events">
        <h1>Wydarzenia:</h1>
        {this.renderCompetitionsWithEvents()}
        <section>
          <Outlet/>
        </section>
      </div>
    );
  }
}
