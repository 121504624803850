import React from 'react';
import './ResetComponent.scss';
import {BACKEND_OPEN_URL} from '../consts.js';
import Logo from '../assets/logo_astra.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { useParams, Link } from 'react-router-dom';

function withParams(Component){
  return props => <Component {...props} params={useParams()}/>;
}

class SetPasswordComponent extends React.Component {

  constructor(props){
    super(props);
    this.state={
      password:'',
      secondPassword: '',
      resetFailed: false,
      isFetching: false,
      errorMessages: {},
      isTokenActive: false,
      passwordChanged: false,
    }
    this.updateItem = this.updateItem.bind(this);
    this.submitReset = this.submitReset.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.checkIfTokenIsActive = this.checkIfTokenIsActive.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderUnactiveToken = this.renderUnactiveToken.bind(this);
  }

  componentDidMount(){
    this.checkIfTokenIsActive();
  }

  checkIfTokenIsActive(){
    this.setState({
      isFetching: true,
    })
    fetch(`${BACKEND_OPEN_URL}/api/check-reset-token/${this.props.params.token}`)
      .then(data=>data.json())
      .then(data => this.setState({
        isFetching: false,
        isTokenActive: data.success,
      }))
      .catch(error=>console.warn(error));
  }

  updateItem(item,ev){
    this.setState({[item]:ev.target.value});
  }

  isFormValid(){
    const {password,secondPassword} = this.state;
    let errorMessages = {};
    if(''==password){
      errorMessages['password']='Zdefiniuj swoje hasło';
    }
    if(password!==secondPassword){
      errorMessages['secondPassword']='Podane hasła nie są takie same';
    }
    if(password.length<8){
      errorMessages['password']='Hasło powinno zawierać min. 8 znaków';
    }
    let hasUppercase =password.match(/[A-Z]/);
    let hasLowercase = password.match(/[a-z]/);
    let hasNumber    = password.match(/[0-9]/);
    if(!hasUppercase || !hasLowercase || !hasNumber){
      errorMessages['password'] = 'Hasło powinno zawierać małe i duże litery, znaki specjalne oraz cyfry';
    }
    this.setState({
      resetFailed: !!Object.keys(errorMessages).length,
      errorMessages,
    });
    return !Object.keys(errorMessages).length;
  }

  submitReset(ev){
    ev.preventDefault();
    if(!this.isFormValid()) return;
    let data = JSON.stringify({
      'password':this.state.password,
      'secondPassword':this.state.secondPassword,
      'token': this.props.params.token,
    });
    this.setState({resetFailed:false});
    fetch(`${BACKEND_OPEN_URL}/api/reset-password`,{
      "credentials": "include",
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(data=>data.json())
    .then(data => this.setState({
      resetFailed:!data.success,
      errorMessages:data.errorMessages,
      passwordChanged:data.success,
    }))
    .catch(error=>console.warn(error));
  }

  renderForm(){
    const {resetFailed,errorMessages,password,secondPassword,passwordChanged} = this.state;
    return (
      <div className="login-panel">
        {resetFailed && (<Alert key="warning" variant="warning">W formularzu występują błędy</Alert>)}
        {passwordChanged && (<Alert key="info" variant="info">Twoje hasło zostało zmienione. Teraz możesz się <Link to="/">zalogować</Link>.</Alert>)}
        <Form onSubmit={this.submitReset}>
          <img src={Logo} className="logo" alt="Logo Klubu"/>
          <Form.Group>
            <Form.Control placeholder="Hasło" type="password" name="password" onChange={(ev)=>this.updateItem('password',ev)} />
            {resetFailed && errorMessages && errorMessages['password'] && (
              <Alert key="warning" variant="warning">{errorMessages['password']}</Alert>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Control placeholder="Powtórz hasło" type="password" name="secondPassword" onChange={(ev)=>this.updateItem('secondPassword',ev)} />
            {resetFailed && errorMessages && errorMessages['secondPassword'] && (
              <Alert key="warning" variant="warning">{errorMessages['secondPassword']}</Alert>
            )}
          </Form.Group>
          <Button variant="primary" type="submit">Zresetuj hasło</Button>
        </Form>
      </div>
    )
  }

  renderUnactiveToken(){
    return (
      <Alert key="warning" variant="warning">Ten token jest już nieaktualny</Alert>
    )
  }

  render(){
    const {isFetching,isTokenActive} = this.state;
    return this.props.params.token && !isFetching && isTokenActive ? this.renderForm() : this.renderUnactiveToken();
  }
}

export default withParams(SetPasswordComponent)
