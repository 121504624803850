import React from 'react';
import './App.scss';
import { Routes, Route, Navigate } from "react-router-dom";
// universal components
import LoaderComponent from "./Loader/LoaderComponent";
import LoginComponent from './Login/LoginComponent';
import ResetComponent from './Reset/ResetComponent';
import SetPasswordComponent from './Reset/SetPasswordComponent';
import RegisterComponent from './Register/RegisterComponent';
import ConfirmAccountComponent from './Register/ConfirmAccountComponent';
import LogoutComponent from './Logout/LogoutComponent';
import ValidateTransactionStatusComponent from './Transaction/ValidateTransactionStatusComponent';
import DownloadTicketComponent from './Transaction/DownloadTicketComponent';
import LetInOutComponent from './LetInOut/LetInOutComponent';
// admin components
import DashboardComponent from './Dashboard/DashboardComponent';
import NewPlaceComponent from './Components/Places/NewPlaceComponent';
import AvailablePlacesComponent from './Components/Places/AvailablePlacesComponent';
import AvailableCompetitionsComponent from './Components/Competitions/AvailableCompetitionsComponent';
import NewCompetitionComponent from './Components/Competitions/NewCompetitionComponent';
import AvailableEventsComponent from './Components/Events/AvailableEventsComponent';
import NewEventComponent from './Components/Events/NewEventComponent';
import TransactionsComponent from './Components/Events/TransactionsComponent';
import NewCarnetComponent from './Components/Carnets/NewCarnetComponent';
import VerifyCarnetComponent from './Components/Carnets/VerifyCarnetComponent';
import NewTicketForEventComponent from './Components/Tickets/NewTicketForEventComponent';
import SelectEventToCreateTicketComponent from './Components/Tickets/SelectEventToCreateTicketComponent';
import VerifyTicketComponent from './Components/Tickets/VerifyTicketComponent';
import UsersComponent from './Components/Others/UsersComponent';
// non-admin Components
import StartPanelComponent from './StartPanel/StartPanelComponent';
import CurrentEventsComponent from './UserComponents/CurrentEvents/CurrentEventsComponent';
import HomeComponent from './UserComponents/HomeComponent/HomeComponent';
import NewTicketForEventUserComponent from './UserComponents/Tickets/NewTicketForEventUserComponent';

import {BACKEND_OPEN_URL} from './consts.js';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading: true,
      isLogged: false,
      isAdmin: false,
      userName: '',
    }
  }

  componentDidMount(){
    fetch(`${BACKEND_OPEN_URL}/api/me`,{
      "credentials": "include",
    })
      .then(data=>data.json())
      .then(data=>this.setState({
        isLogged:data.isLoggedIn,
        isAdmin:data.isAdmin,
        userName:data.user,
      }))
      .finally(()=>this.setState({isLoading:false}));
  }

  render() {
    return this.state.isLoading ? <LoaderComponent/> : (
      <div className="App">
        <Routes>
          {this.state.isAdmin && (
            <Route path="/" element={<DashboardComponent isAdmin={this.state.isAdmin}/>}>
              <Route path="new-place" element={<NewPlaceComponent/>}/>
              <Route path="available-places" element={<AvailablePlacesComponent/>}/>
              <Route path="competitions" element={<AvailableCompetitionsComponent/>}/>
              <Route path="new-competition" element={<NewCompetitionComponent/>}/>
              <Route path="events/:id/transactions" element={<TransactionsComponent/>}/>
              <Route path="events" element={<AvailableEventsComponent/>}/>
              <Route path="new-event" element={<NewEventComponent/>}/>
              <Route path="new-carnet" element={<NewCarnetComponent/>}/>
              <Route path="verify-carnet" element={<VerifyCarnetComponent/>}/>
              <Route path="/" element={<SelectEventToCreateTicketComponent/>}/>
              <Route path="events/:id/new-ticket" element={<NewTicketForEventComponent/>}/>
              <Route path="verify-ticket" element={<VerifyTicketComponent/>}/>
              <Route path="users" element={<UsersComponent/>}/>
            </Route>
          )}
          <Route path="/" element={<StartPanelComponent isLogged={this.state.isLogged} userName={this.state.userName} />}>
            <Route path="/" element={<CurrentEventsComponent/>}/>
            <Route path="/wydarzenie/:id" element={<NewTicketForEventUserComponent userName={this.state.userName}/>}/>
            <Route path="/me" element={<HomeComponent isLogged={this.state.isLogged}/>}/>
          </Route>
          <Route path="/login" element={<LoginComponent/>} />
          <Route path="/reset-password" element={<ResetComponent/>} />
          <Route path="/set-password/:token" element={<SetPasswordComponent/>} />
          <Route path="/logout" element={<LogoutComponent/>} />
          <Route path="/register" element={<RegisterComponent isLogged={this.state.isLogged}/>} />
          <Route path="/vverifyy" element={<LetInOutComponent />} />
          <Route path="/confirm/:id" element={<ConfirmAccountComponent isLogged={this.state.isLogged}/>} />
          <Route path="/transaction/:id" element={<ValidateTransactionStatusComponent />} />
          <Route path="/pobierz-bilet/:uid/:verificationCode" element={<DownloadTicketComponent />} />
          <Route path="*" element={<Navigate to="/" replace={true}/>}/>
        </Routes>
      </div>
    )
  }
}

export default App;
