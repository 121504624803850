import React, {props} from 'react';
import './Tribune.scss';
import {Form,Button} from 'react-bootstrap';
import SectorComponent from '../Sector/SectorComponent';

const TOP_LEFT = 'TOP_LEFT';
const TOP_RIGHT = 'TOP_RIGHT';
const BOTTOM_LEFT = 'BOTTOM_LEFT';
const BOTTOM_RIGHT = 'BOTTOM_RIGHT';

const EMPTY_SECTOR = {
  name: '',
  start_number: 1,
  is_numbering_continous: false,
  is_numbering_per_row: true,
  are_seats_crossed: false,
  numbering_type: TOP_LEFT,
  rows: [],
}

export default class TribuneComponent extends React.Component {
  constructor(props){
    super(props);
    this.renderSectors = this.renderSectors.bind(this);
    this.addNewSector = this.addNewSector.bind(this);
    this.removeSector = this.removeSector.bind(this);
  }

  updateSector(id,sector){
    let newSectors = this.props.tribune.sectors.map((s,nr) => id===nr ? {...sector} : s);
    this.props.onUpdate('sectors',newSectors);
  }

  renderSectors(){
    return this.props.tribune.sectors?.map((sector,nr)=>(
      <div className="sector" key={`sector-${nr}`}>
        <SectorComponent
          sector={sector}
          onUpdate={(sector)=>this.updateSector(nr,sector)}
          removeSector={()=>this.removeSector(nr)}
          has_tribune_numbers={this.props.tribune.has_numbers}
          editable={this.props.editable}
        />
      </div>
    ));
  }

  addNewSector(){
    let sectors = [
      ...this.props.tribune?.sectors,
      {...EMPTY_SECTOR},
    ];
    this.props.onUpdate('sectors',sectors);
  }

  removeSector(sectorNr){
    let sectors = [
      ...this.props.tribune?.sectors?.filter((s,id) => id!==sectorNr),
    ];
    this.props.onUpdate('sectors',sectors);
  }

  render(){
    return (
      <Form className="tribune-wrapper">
        {this.props.editable && (<Button variant="danger" onClick={this.props.removeTribune}>Usuń trybunę</Button>)}
        <Form.Group>
          <Form.Check
            type="checkbox"
            label="Trybuna z miejscami numerowanymi"
            checked={this.props.tribune.has_numbers}
            onChange={ev=>this.props.onUpdate('has_numbers',!!ev.target.checked)}
            disabled={!this.props.editable}
          />
        </Form.Group>
        {this.props.tribune.has_numbers && (<Form.Group>
          <Form.Label>Początek numeracji:</Form.Label>
          <Form.Control
            name="first_number"
            autoComplete="off"
            value={this.props.tribune.first_number}
            onChange={ev=>this.props.onUpdate(ev.target.name,ev.target.value)}
            disabled={!this.props.editable}
          />
        </Form.Group>)}
        <Form.Group>
          <Form.Label>Nazwa trybuny:</Form.Label>
          <Form.Control
            name="name"
            autoComplete="off"
            value={this.props.tribune.name}
            onChange={ev=>this.props.onUpdate(ev.target.name,ev.target.value)}
            disabled={!this.props.editable}
          />
        </Form.Group>
        <div className="sectors">
          {this.renderSectors()}
        </div>
        {this.props.editable && (<Button variant="warning" onClick={this.addNewSector}>Dodaj nowy sektor</Button>)}
      </Form>
    )
  }
}
