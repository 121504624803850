import React from 'react';
import './Dashboard.scss';
import { Outlet } from "react-router-dom";
import MenuComponent from '../Menu/MenuComponent';
import Logo from '../assets/logo_astra.png';

export default class DashboardComponent extends React.Component {
  constructor(props){
    super(props);
    this.state={
      isMinimized: true,
    }
    this.toggleMinimize = this.toggleMinimize.bind(this);
  }

  componentDidMount(){
    if(!this.props.isAdmin) document.location.replace("/");
  }

  toggleMinimize(){
    this.setState({
      isMinimized: !this.state.isMinimized,
    })
  }

  render(){
    const {isMinimized} = this.state;
    return (
      <div className={`Dashboard${isMinimized ? ' minimized' : ''}`}>
        <span className="toggle-minimize" onClick={this.toggleMinimize}>
          {this.state.isMinimized ? 'Rozwiń menu' : 'Zamknij menu'}
        </span>
        <nav>
          <img src={Logo} className="logo" alt="Logo Klubu"/>
          <MenuComponent/>
        </nav>
        <section>
          <Outlet/>
        </section>
      </div>
    );
  }
}
