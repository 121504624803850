import React from 'react';
import './NewCarnetComponent.scss';
import { BACKEND_URL } from '../../consts';
import NewCarnetForCompetitionComponent from './NewCarnetForCompetitionComponent';
import { Accordion, Alert } from 'react-bootstrap';

export default class NewCarnetComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      competitions: [],
    }
    this.getCompetitionsWithCarnetsAndTickets = this.getCompetitionsWithCarnetsAndTickets.bind(this);
  }

  componentDidMount(){
    this.getCompetitionsWithCarnetsAndTickets();
  }

  getCompetitionsWithCarnetsAndTickets(){
    fetch(`${BACKEND_URL}/api/competition/with-carnets-and-tickets`,{
      "credentials": "include",
    })
      .then(data=>data.json())
      .then(data=>this.setState({competitions:[...data.competitions]}))
      .catch(e=>console.warn(e));
  }

  renderCompetitions(){
    return (
      <Accordion>
        {this.state.competitions.length ? this.state.competitions.map((c,k) => (
          <div className="competition" key={k}>
            <NewCarnetForCompetitionComponent competition={c} eventKey={k} refresh={this.getCompetitionsWithCarnetsAndTickets} />
          </div>
        )) : (<Alert variant="primary">Brak rozgrywek</Alert>)
        }
      </Accordion>
    )
  }

  render(){
    return (
      <div className="carnets">
        <h1>Rozgrywki:</h1>
        {this.renderCompetitions()}
      </div>
    );
  }
}
