import React from 'react';
import { Breadcrumb, Card, Alert } from 'react-bootstrap';
import {BACKEND_OPEN_URL} from '../consts.js';
import { useParams } from 'react-router-dom';

function withParams(Component){
  return props => <Component {...props} params={useParams()}/>;
}

class ValidateTransactionStatusComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      success: false,
      isLoading: false,
      message: '',
    }
    this.fetchItem = this.fetchItem.bind(this);
  }

  componentDidMount(){
    const {id} = this.props.params;
    this.fetchItem(id);
  }

  fetchItem(id){
    this.setState({
      isLoading: true,
    });
    fetch(`${BACKEND_OPEN_URL}/api/reservation/transaction-status/${id}`,{
      credentials: "include",
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(data => data.json())
      .then(data => {
        this.setState({
          isLoading: false,
          success: data.success,
          message: data.message,
        })
      })
      .catch(err=>console.warn(err));
  }

  render(){
    const {isLoading,message,success}=this.state;
    return (
      <div className="home-component">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Start</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Header>Status zamówienia</Card.Header>
          <Card.Body>
            <Card.Text>
              {!isLoading && (<Alert variant={success ? 'success':'warning'}>{message}</Alert>)}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withParams(ValidateTransactionStatusComponent);
