import React from 'react';
import {BACKEND_OPEN_URL} from '../consts.js';

export default class Logout extends React.Component {

  componentDidMount(){
    fetch(`${BACKEND_OPEN_URL}/api/logout`,{
      "credentials": "include",
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    })
      .then(()=>document.location.replace('/'))
      .catch(err=>console.warn(err))
  }

  render(){
    return(<h2>Trwa wylogowywanie...</h2>);
  }
}
